import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import { Modal, Grid, IconButton, TextField,Select,
    MenuItem, FormControl,InputLabel, Link, useMediaQuery, 
    InputAdornment} from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const formatTime = (time) => {
  if(!time) return ""
  const [hours, minutes] = time?.split(":").map(Number); // Split into hours and minutes
  const period = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
};


const useStyles = makeStyles((theme) => ({
    submit: {
      backgroundColor: "#de7d1e",
    },
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default function StoreLocation() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();

  const {
    editStore,
    fetchStores,
    deleteStore,
  } = api;

  const settings = useSelector(state => state.settingsdata.settings);
  const stores = useSelector(state => state.stores);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const rootRef = useRef(null);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const userdata = useSelector(state => state.usersdata);

  

  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchStores(auth.info.uid));
    }
    else dispatch(fetchStores(auth.info.profile?.carrierId));
    
},[dispatch,fetchStores,auth?.info?.uid]);



const [loading, setLoading] = useState(false);
const [Location, setLocation] = useState(null);
const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

const [rowToUpdate,setRowToUpdate]=useState({})
const [LocationModal, setLocationModal] = useState(false);
const [loadData,setLoadData]=useState(null)
const [workingDates, setWorkingDates] = useState(null);



const openLocationModal = (rowData) => {
    setLoadData(rowData)
    if(!Location||rowToUpdate?.Location!==rowData.id){
        setLocation(rowData.Location
        ? {
            coords:rowData.Location.coords ,
            description: rowData.Location.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, Location: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, Location: null }))

    // Open the modal
    setLocationModal(true);
  };

const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor
    return "#01aac1"
}


const columns = [
    {
        title: "Identification",
        field: "identification",
        editable: "never",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    { 
        title:"Location", 
        field: 'Location',
        render: (rowData) =>
            rowData.Location||Location?.description ? (
                <div>
                  <p>{(Location?.description&&rowToUpdate?.Location===rowData.id)?Location?.description:rowData?.Location?.description}</p>
                </div>
            ) : (
                "Missing Location"
        ),
        editComponent: (props) => (
            <button onClick={() => openLocationModal(props.rowData)}>
                {props.rowData.Location||Location?.description ? (
                <div>
                    <p>{(Location?.description&&rowToUpdate?.Location===props.rowData.id)?
                     Location?.description
                    :props.rowData?.Location?.description}</p>
                </div>
                ) : (
                "Add"
                )}
            </button>
        ),
        cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
        headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        },
     
     },
    {
        title: "Custom Name",
        field: "name",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    {
        title: "Store Type",
        field: "isMain",
        type: "boolean",
        initialEditValue: true,
        render:(rowData)=>(
            <div>
                 {rowData?.isMain?"A2B Store":"Agent Store"}
            </div>
        ),
        cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            zIndex: 2
          },
        headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            zIndex: 2
        },
      },
    {
        title: "Active Status",
        field: "isActive",
        type: "boolean",
        initialEditValue: true,
        cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            zIndex: 2
          },
        headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            zIndex: 2
        },
      },
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},

  ];


const handleSetLocation=()=>{
    if(!Location) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,Location:Location}
        dispatch(editStore(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchStores(auth.info.uid));
        }
        else dispatch(fetchStores(auth.info.profile?.carrierId));
    }
    setLocationModal(false)
    
}

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
      return e.id===id
    })
    if(targetUser.length>0) return targetUser[0]
    return null

  }

  return (
    (stores.loading&&stores)? <CircularLoading/>:
     <>
        <MaterialTable
        title= 'Manage Stores'
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
        data={stores.stores||[]}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
        }}
        localization={{ body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
            editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
          onRowAdd: async (newData) =>
            new Promise((resolve,reject) => {
                setTimeout(async() => {
                    if(auth.info?.profile?.usertype === 'dispatcher'){
                      newData['carrierId'] = auth.info?.profile?.carrierId;
                     }
                    else newData['carrierId'] = auth.info.uid;
                    newData['Location']=Location
                   
                    newData['createdAt'] = new Date().toISOString();

                    if(Location){
                              dispatch(api.createStores(newData))
                                setLocation(null)
                                if(auth?.info?.profile?.usertype === 'fleetadmin'){
                                    dispatch(fetchStores(auth.info.uid));
                                }
                                else dispatch(fetchStores(auth.info.profile?.carrierId));
                       
                        
                    }
                    else{
                      setCommonAlert({open:true,msg:"Please fill all fields"})
                    }
               
                    resolve();
                }, 600);
            }),

            onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
                setTimeout(() => {
                  dispatch(editStore(newData));
                  if(auth?.info?.profile?.usertype === 'fleetadmin'){
                    dispatch(fetchStores(auth.info.uid));
                   }
                  else dispatch(fetchStores(auth.info.profile?.carrierId));

                    resolve();
                   
                }, 600);
            }),
            onRowDelete: oldData =>
            new Promise(resolve => {
               setTimeout(() => {

                  dispatch(deleteStore(oldData))

                  if(auth?.info?.profile?.usertype === 'fleetadmin'){
                        dispatch(fetchStores(auth.info.uid));
                    }
                  else dispatch(fetchStores(auth.info.profile?.carrierId));

                   resolve();
                }, 600);
            })
            
        }}
        />

        
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={LocationModal}
        onClose={()=>setLocationModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Store Location"}
              value={Location}
              className={classes.items}
              onChange={(value) => {
                setLocation(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetLocation}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor() }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>



      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
     </>
  );
}


function generateRandomString(length) {
  let characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const sxStyles={
  typography:{
    fontSize:13,
    opacity:0.5
  }
}