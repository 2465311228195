import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import { Modal, Grid, IconButton, TextField,Select,
    MenuItem, FormControl,InputLabel, Link, useMediaQuery, 
    InputAdornment} from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UsersCombo from 'components/UsersCombo';
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const formatTime = (time) => {
  if(!time) return ""
  const [hours, minutes] = time?.split(":").map(Number); // Split into hours and minutes
  const period = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format (0 becomes 12)
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
};


const useStyles = makeStyles((theme) => ({
    submit: {
      backgroundColor: "#de7d1e",
    },
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default function LoadBoard() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();

  const {
    addLoad,
    editRoutes,
    fetchRoutes,
    deleteRoutes,
    fetchUsersOnce,
    fetchStores
  } = api;

  const settings = useSelector(state => state.settingsdata.settings);
  const routes = useSelector(state => state.routes);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const rootRef = useRef(null);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const userdata = useSelector(state => state.usersdata);
  const stores = useSelector(state => state.stores);

  const [availableStores,setAvailableStores] = useState([])
  const [selectedStore,setSelectedStore] = useState(null)

  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchStores(auth.info.uid));
    }
    else dispatch(fetchStores(auth.info.profile?.carrierId));
    
},[dispatch,fetchStores,auth?.info?.uid]);


  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchRoutes(auth.info.uid));
    }
    else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    
},[dispatch,fetchRoutes,auth?.info?.uid]);

useEffect(()=>{
  if(stores?.stores&&stores?.stores.length>0){
    const filteredStores = stores.stores
                        .filter(e=>e.isActive)
                        .map(e=>{
                          return{...e,desc:`${e.name} (${e?.isMain?"A2B Store":"Agent Store"})`}
                        })
    setAvailableStores(filteredStores)
  }
  else{
    setAvailableStores([])
  }
},[stores])

const [loading, setLoading] = useState(false);
const [pickup, setPickup] = useState(null);
const [drop, setDrop] = useState(null);

const [pickupDate, setPickupDate] = useState(null);
const [dropDate, setDropDate] = useState(null);
const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

const [rowToUpdate,setRowToUpdate]=useState({})
const [PickupModal, setPickupModal] = useState(false);
const [DropModal, setDropModal] = useState(false);
const [loadData,setLoadData]=useState(null)
const [workingDates, setWorkingDates] = useState(null);
const [workingRouteModal,setWorkingRouteModal]  = useState(false)


const openWorkingDateModal = (rowData) => {
  setLoadData(rowData)
  if(!workingDates||rowToUpdate?.workingDates!==rowData.id){
    setWorkingDates(rowData.workingDates||null)
  }
  if(rowData?.id)  setRowToUpdate(e => ({ ...e, workingDates: rowData.id })); 

  // Open the modal
  setWorkingRouteModal(true);
};

const openPickupModal = (rowData) => {
    setLoadData(rowData)
    if(!pickup||rowToUpdate?.pickup!==rowData.id){
        setPickup(rowData.pickup
        ? {
            coords:rowData.pickup.coords ,
            description: rowData.pickup.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, pickup: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, pickup: null }))

    // Open the modal
    setPickupModal(true);
  };

const openDropModal = (rowData) => {
    setLoadData(rowData)
    if(!drop||rowToUpdate?.drop!==rowData.id){
        setDrop(rowData.drop
        ? {
            coords:rowData.drop.coords ,
            description: rowData.drop.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, drop: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, drop: null}))
    // Open the modal
    setDropModal(true);
  };

const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor
    return "#01aac1"
}

const daysOfTheWeek = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" }
];

const sortWorkingDatesByDay = (targetWorkingDates) => {
  const dayOrder = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };

  return targetWorkingDates.sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);
};

const columns = [
    {
        title: "Identification",
        field: "identification",
        editable: "never",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    { 
        title:"Pick up", 
        field: 'pickup',
        render: (rowData) =>
            rowData.pickup||pickup?.description ? (
                <div>
                <p>{(pickup?.description&&rowToUpdate?.pickup===rowData.id)?pickup?.description:rowData?.pickup?.description||pickup?.description}</p>
                </div>
            ) : (
                "Missing Pick Up"
        ),
        editComponent: (props) => (
            <button onClick={() => openPickupModal(props.rowData)}>
                {props.rowData.pickup||pickup?.description ? (
                <div>
                    <p>{(pickup?.description&&rowToUpdate?.pickup===props.rowData.id)?
                     pickup?.description
                    :props.rowData?.pickup?.description||pickup?.description}</p>
                </div>
                ) : (
                "Add"
                )}
            </button>
        ),
        cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
        headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        },
     
     },
    { title: "Drop off", field: 'drop',
    render: (rowData) =>
        rowData.drop||drop?.description ? (
            <div>
            <p>{(drop?.description&&rowToUpdate?.drop===rowData.id)?drop?.description:rowData?.drop?.description||drop?.description}</p>
            </div>
        ) : (
            "Missing Drop off"
    ),
    editComponent: (props) => (
        <button onClick={() => openDropModal(props.rowData)}>
            {props.rowData.drop||drop?.description ? (
            <div>
                <p>{(drop?.description&&rowToUpdate?.drop===props.rowData.id)?drop?.description:props.rowData?.drop?.description||drop?.description}</p>
            </div>
            ) : (
             "Add"
            )}
        </button>
    ),
    cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
    headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        }
    },
    {
        title: "Name",
        field: "name",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    {
      title: "Pickup Address",
      field: "pickupAddress",
      initialEditValue: '',
      editable:'never',
      cellStyle: { 
        textAlign: isRTL === "rtl" ? "right" : "center", 
        minWidth: 250,
        width: 250,
        zIndex: 2
      },
      headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
      },
    },
    {
      title: "Drop Address",
      field: "dropAddress",
      initialEditValue: '',
      editable:'never',
      cellStyle: { 
        textAlign: isRTL === "rtl" ? "right" : "center", 
        minWidth: 250,
        width: 250,
        zIndex: 2
      },
      headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
      },
    },
    { 
      title:"Delivery Dates & Times", 
      field: 'workingDates',
      editable: "onUpdate",
      render: (rowData) =>
          (rowData?.workingDates && rowData?.workingDates?.length > 0)||(workingDates && workingDates?.length >0 ) ? (
              <Box>
                  {(workingDates&&rowToUpdate?.workingDates===rowData.id)?
                              sortWorkingDatesByDay(workingDates).map((e,index)=>{
                                 return(
                                  <Box key={index} sx={{display:"flex",alignItems:"center",gap:5,justifyContent:"center",paddingBottom:1}}> 
                                        <Box>{e.day}</Box>
                                        <Box>{formatTime(e.pickupTime)} - {formatTime(e.dropTime)}</Box>
                                  </Box>
                                 )
                              })
                               
                         :
                         rowData?.workingDates  ? sortWorkingDatesByDay(rowData?.workingDates).map((e,index)=>{
                                 return(
                                  <Box key={index} sx={{display:"flex",alignItems:"center",gap:5,justifyContent:"center",paddingBottom:1}}> 
                                        <Box>{e.day}</Box>
                                        <Box>{formatTime(e.pickupTime)} - {formatTime(e.dropTime)}</Box>
                                  </Box>
                                 )
                              }) :  "Missing Working Dates"
                      }
              </Box>
          ) : (
              "Missing Working Dates"
      ),
      editComponent: (props) => (
          <button onClick={() => openWorkingDateModal(props.rowData)} >
              {(props?.rowData?.workingDates && props?.rowData?.workingDates?.length > 0)||(workingDates && workingDates?.length >0 ) ? (
               <div>
                 {(workingDates&&rowToUpdate?.workingDates===props?.rowData?.id)?
                              workingDates.map((e,index)=>{
                                 return(
                                  <Box key={index} sx={{display:"flex",alignItems:"center",gap:5,justifyContent:"center",paddingBottom:1}}> 
                                        <Box>{e.day}</Box>
                                        <Box>{e.pickupTime} - {e.dropTime}</Box>
                                  </Box>
                                 )
                              })
                               
                         :
                         props?.rowData?.workingDates?props?.rowData?.workingDates.map((e,index)=>{
                                 return(
                                  <Box key={index} sx={{display:"flex",alignItems:"center",gap:5,justifyContent:"center",paddingBottom:1}}> 
                                        <Box>{e.day}</Box>
                                        <Box>{e.pickupTime} - {e.dropTime}</Box>
                                  </Box>
                                 )
                              })
                              :
                              "Add Working Dates"
                         }
               </div>
              ) : (
              "Add Working Dates"
              )}
          </button>
      ),
      cellStyle: {
      textAlign: isRTL === "rtl" ? "right" : "center",
      width: 300,
      minWidth: 300,
      zIndex: 2
      },
      headerStyle: {
      fontWeight: "bold",
      backgroundColor: pageBackgroundColor(),
      color: "white",
      textAlign: "center",
      zIndex: 2
      },
   
   },
    {
        title: "Active",
        field: "isActive",
        type: "boolean",
        initialEditValue: true,
        cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            zIndex: 2
          },
        headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            zIndex: 2
        },
      },
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},

  ];


const isPickupAndDropAreValid = async (pickup, drop) => {

  if (!pickup || !drop) return false;

  try {
    const directionService = new window.google.maps.DirectionsService();
    const result = await directionService.route({
      origin: new window.google.maps.LatLng(pickup.coords.lat, pickup.coords.lng),
      destination: new window.google.maps.LatLng(drop.coords.lat, drop.coords.lng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    return result.status === window.google.maps.DirectionsStatus.OK;
  } catch (error) {
    //console.error(error);
    return false;
  }
};

const handleSetPickup=()=>{
    if(!pickup||!pickup?.name) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,pickup:pickup,pickupAddress:pickup?.name||datatToBeUpdate?.pickupAddress||""}
        dispatch(editRoutes(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }
    setPickupModal(false)
    setSelectedStore(null)
    
}
const handleSetWorkingDate=()=>{

    if(!workingDates) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,workingDates:workingDates}
        dispatch(editRoutes(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }
    setWorkingRouteModal(false)
}

const handleSetDrop=()=>{
    if(!drop) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,drop:drop,dropAddress:drop?.name||datatToBeUpdate?.pickupAddress||""}
        dispatch(editRoutes(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }
    setDropModal(false)
    setSelectedStore(null)
}


  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
      return e.id===id
    })
    if(targetUser.length>0) return targetUser[0]
    return null

  }

  const [openAddSchedule,setOpenAddSchedule] = useState(false)
  const [day,setDay]=useState("")
  const [pickupTime, setPickupTime] = useState("00:00");
  const [dropTime, setDropTime] = useState("00:00");


  const handleChange = (event) => {
    setDay(event.target.value);
  };

  const AddSchedule = () => {
    if (day && pickupTime && dropTime) {
      // Check if dropTime is earlier than pickupTime
      if (dropTime <= pickupTime) {
        alert("Drop time must be later than pickup time.");
        return; // Exit the function to prevent adding invalid times
      }
  
      if (!workingDates || workingDates.length === 0) {
        setWorkingDates([{ day, pickupTime, dropTime, id: generateRandomString(20) }]);
      } else {
        setWorkingDates(e => [...e, { day, pickupTime, dropTime, id: generateRandomString(20) }]);
      }
  
      setDay("");
      setPickupTime("00:00");
      setDropTime("00:00");
      setOpenAddSchedule(false);
    }
  };

  
  const cancelAdding=()=>{
    setDay("")
    setPickupTime(null)
    setDropTime(null)
    setOpenAddSchedule(false)
  }

  return (
    (routes.loading&&routes)? <CircularLoading/>:
     <>
        <MaterialTable
        title= 'Manage Routes'
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
        data={routes.routes||[]}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
        }}
        localization={{ body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
            editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
          onRowAdd: async (newData) =>
            new Promise((resolve,reject) => {
                setTimeout(async() => {
                    if(auth.info?.profile?.usertype === 'dispatcher'){
                      newData['carrierId'] = auth.info?.profile?.carrierId;
                     }
                    else newData['carrierId'] = auth.info.uid;
                    newData['drop']=drop
                    newData['pickup']=pickup
                   
                    newData['createdAt'] = new Date().toISOString();
                    
                    if(drop&&pickup){
                        const isValidRoutes=await isPickupAndDropAreValid(pickup,drop)
                        if(isValidRoutes){
                              newData["pickupAddress"]=pickup?.name
                              newData["dropAddress"]=drop?.name
                              dispatch(api.createRoutes(newData))
                                setPickup(null)
                                setDrop(null)
                                if(auth?.info?.profile?.usertype === 'fleetadmin'){
                                    dispatch(fetchRoutes(auth.info.uid));
                                }
                                else dispatch(fetchRoutes(auth.info.profile?.carrierId));
                        }
                        else{
                            setCommonAlert({
                              open: true,
                              msg: 'The pickup and drop-off locations you provided are not valid. Please check the coordinates and try again.',
                            });
                        }
                        
                    }
                    else{
                      setCommonAlert({open:true,msg:"Please fill all fields"})
                    }
               
                    resolve();
                }, 600);
            }),

            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  // Destructure to remove the tableData property
                  const { tableData, ...cleanData } = newData;
                  
                  // Now pass only cleanData to editRoutes
                  dispatch(editRoutes(cleanData));
            
                  // Re-fetch routes as needed
                  if (auth?.info?.profile?.usertype === "fleetadmin") {
                    dispatch(fetchRoutes(auth.info.uid));
                  } else {
                    dispatch(fetchRoutes(auth.info.profile?.carrierId));
                  }
                  
                  resolve();
                }, 600);
              }),            
            onRowDelete: oldData =>
            new Promise(resolve => {
               setTimeout(() => {

                  dispatch(deleteRoutes(oldData))

                  if(auth?.info?.profile?.usertype === 'fleetadmin'){
                        dispatch(fetchRoutes(auth.info.uid));
                    }
                  else dispatch(fetchRoutes(auth.info.profile?.carrierId));

                   resolve();
                }, 600);
            })
            
        }}
        />

        
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={PickupModal}
        onClose={()=>{
          setPickupModal(false)
          setSelectedStore(null)
        }}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            {/* <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Pick Up"}
              value={pickup}
              className={classes.items}
              onChange={(value) => {
                setPickup(value);
              }}
            /> */}
             <UsersCombo
                className={classes.items}
                placeholder={"Choose Store"}
                users={availableStores}
                value={selectedStore}
                onChange={(event, newValue) => {
                  setPickup({...newValue?.Location,name:newValue?.name})
                  setSelectedStore(newValue);
                }}
              />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetPickup}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor() }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={workingRouteModal}
        onClose={()=>setWorkingRouteModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >

              <Box
                className={classes.paper}
                style={{
                  justifyContent:"center",
                  alignItems: "center",
                  gap:0,
                  backgroundColor: "white",
                  marginTop: 0,
                  borderRadius: 8,
                  overflow:"hidden",
                  padding:"0 10px 0 10px",
                  width:"80%",
                  maxWidth:500,
                
                }}
              >
                {!openAddSchedule&&<Box sx={{marginTop:1,}}>
                    <IconButton onClick={()=>setOpenAddSchedule(true)} style={{background:pageBackgroundColor()}}>
                        <AddIcon  sx={{fontSize:20,color:"white"}}/>
                    </IconButton>
                </Box>}

              {openAddSchedule&&
              <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px",flexDirection:"column",marginTop:"10px"}}>
                  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:3,marginLeft:4,flexWrap:'wrap',flexDirection:"column"}}>
                      <Box sx={{width:"100%"}}>
                          <Typography sx={sxStyles.typography}>Select Day</Typography>
                          <Select 
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={day}
                                labelColor='#cc78b2'
                                label='Select Day'
                                onChange={handleChange}
                                sx={{color:"black",m: 1, minWidth: 200,}}
                                fullWidth
                              >
                                {daysOfTheWeek.map((e,index)=>{
                                  return <MenuItem  ket={index}value={e.value}>{e.label}</MenuItem>
                                })}
                          </Select>
                      </Box>
                      <Box sx={{display:'flex',justifyContent:"center",alignItems:"center",gap:10,width:"100%",flexWrap:"wrap"}}>
                        <Box>
                            <TextField
                                    id="time"
                                    label="Pickup Time"
                                    type="time"
                                    value={pickupTime}
                                    onChange={(event)=>{
                                      setPickupTime(event.target.value)
                                    }}
                                    defaultValue="07:30"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      step: 300, // 5 min
                                    }}
                                    sx={{ width: 150 }}
                                  />
                        </Box>
                        <Box>
                            <TextField
                                    id="time"
                                    label="Drop Time"
                                    type="time"
                                    value={dropTime}
                                    // onChange={setDropTime}
                                    onChange={(event)=>{
                                      setDropTime(event.target.value)
                                    }}
                                    defaultValue="07:30"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      step: 300, // 5 min
                                    }}
                                    sx={{ width: 150 }}
                                      />
                        </Box>

                      </Box>


                    </Box>

                  <Box sx={{marginTop:"10px",marginBottom:"10px"}}>
                      <Button style={{background:"green"}} onClick={AddSchedule}>Add</Button> &ensp; &ensp;
                      <Button style={{background:"red"}} onClick={cancelAdding}>Cancel</Button>
                  </Box>
              </Box>
            }


                {!openAddSchedule&&
                 <Box  sx={{ width:"100%",paddingTop:3,height:"55vh",overflow:"auto",}}>
                    {
                    workingDates && workingDates.length>0?
                        <Box sx={{display:"flex",gap:2,flexWrap:"wrap",justifyContent:"flex-start",alignItems:"center"}}>
                            {workingDates.map((e,index)=>{
      
                                return <EachSchedules data={e} setWorkingDates={setWorkingDates} key={index}/>
                              })}
                          
                        </Box>
                      : <Box sx={{height:"100%",width:"100%",textAlign:"center",paddingTop:10}}>
                          No Working Dates Found
                        </Box>

                    }
                </Box>}

                {!openAddSchedule&&<Box  sx={{justifyContent:"center",alignItems:"center",width:"100%",textAlign:"center"}}>
                  {loading ? (
                    <Box
                      style={{ minHeight: "5vh" }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                      <Button
                        onClick={handleSetWorkingDate}
                        variant="contained"
                        color="secondaryButton"
                        style={{background:pageBackgroundColor() }}
                      >
                        {t("save")}
                      </Button>
                  )}
                </Box>}
                
              </Box>

      </Modal>


      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={DropModal}
        onClose={()=>{
          setDropModal(false)
          setSelectedStore(null)
        }}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            {/* <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Drop off"}
              value={drop}
              className={classes.items}
              onChange={(value) => {
                setDrop(value);
              }}
            /> */}

            <UsersCombo
                className={classes.items}
                placeholder={"Choose Store"}
                users={availableStores}
                value={selectedStore}
                onChange={(event, newValue) => {
                  setDrop({...newValue?.Location,name:newValue?.name})
                  setSelectedStore(newValue);
                }}
              />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetDrop}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor() }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>




      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
     </>
  );
}

const EachSchedules=({data,setWorkingDates})=>{
  const [day,setDay]=useState("")
  const [pickupTime,setPickupTime]=useState(null)
  const [dropTime,setDropTime]=useState(null)

  useEffect(() => {
    setDay(data.day);
    setPickupTime(data.pickupTime);
    setDropTime(data.dropTime);
  }, [data]);

  const deleteSchedule=()=>{
    setWorkingDates(ele=>ele.filter(e=>{
       return e.id !== data.id
      }))
  }
  return (
    <Box sx={{border:'1px solid red',width:130,borderRadius:"10px",paddingTop:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"3px"}}>
        <Box>{day}</Box>
        <Box sx={{fontSize:"13px",opacity:0.7}}>{formatTime(pickupTime)} - {formatTime(dropTime)}</Box>
        <Box ><IconButton onClick={deleteSchedule}><DeleteIcon sx={{fontSize:"18px",color:"red"}}/></IconButton></Box>
    </Box>
  )
}

function generateRandomString(length) {
  let characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const sxStyles={
  typography:{
    fontSize:13,
    opacity:0.5
  }
}