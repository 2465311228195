import React, { useState, useEffect, useContext, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { Link, Typography } from "@material-ui/core";
import {
  Alert,
  Box,
  Button as MuiButton,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import UsersCombo from "components/UsersCombo";
import AlertDialog from "components/AlertDialog";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
   modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",alignItems:"center",
    lineBreak:"anywhere",
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width:"60%",
    maxWidth:"60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '@media (max-width: 700px)':{ width:"90%"} ,
    maxHeight:'70vh',
    overflow:"auto",
  },
  modalContainer: {},

  eachDispatcher: {
    "&:hover": {
      backgroundColor: "rgba(180, 180, 180, .2)",
    },
  },
  showAllButton: {
    "&:hover": {
      // backgroundColor: '#01aac1',
      color: "#fff",
    },
  },
  eachSettingContaiiner: {
    width: "50%",
  },
}));

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { addUser, editUser, deleteUser, checkUserExists, fetchUsersOnce ,    updateLicenseImage,
  } =
    api;
  const [data, setData] = useState([]);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.usersdata);
  const classes = useStyles();
  const rootRef = useRef(null);

  const loaded = useRef(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  const giveIdThenIwillGiveYouData = (id) => {
    if (!userdata.users) return null;
    const targetUser = userdata.users.filter((e) => {
      return e.id === id;
    });
    if (targetUser.length > 0) return targetUser[0];
    return null;
  };

  useEffect(() => {
    if (staticusers) {
      if (auth.info.profile.usertype === "admin") {
        let users = staticusers.filter(
          (user) =>
            user.usertype === "customer" && user?.carrierId === auth.info.uid
        );
        let userDetail = [];
        for (const user of users) {
          if (user?.usertype === "fleetadmiin") {
            let carrier = staticusers.filter(
              (individual) => individual.id === user.carrierId
            );
            if (carrier.length > 0) {
              user["ownerName"] =
                carrier[0].firstName + " " + carrier[0].lastName;
              user["ownerEmail"] = carrier[0].email;
            }
            userDetail.push(user);
          } else if (user?.usertype === "shipper") {
            let broker = staticusers.filter(
              (individual) => individual.id === user.brokerId
            );
            if (broker.length > 0) {
              user["ownerName"] =
                broker[0].firstName + " " + broker[0].lastName;
              user["ownerEmail"] = broker[0].email;
            }
            userDetail.push(user);
          } else if (user?.usertype === "dispatcher" && user.independent) {
            let dispatcher = staticusers.filter(
              (individual) => individual.id === user.dispatcherId
            );
            if (dispatcher.length > 0) {
              user["ownerName"] =
                dispatcher[0].firstName + " " + dispatcher[0].lastName;
              user["ownerEmail"] = dispatcher[0].email;
            }
            userDetail.push(user);
          }
        }
        setData(userDetail);
      } else {
        const filterdUsers=  staticusers.filter(
          (user) =>
            user.usertype === "customer" &&
            (user?.carrierId === auth.info.uid ||
              user?.carrierId === auth?.info?.profile?.carrierId)
        )
        setData(filterdUsers.length===0?[]:filterdUsers.map(e=>{
           return {...e,tableData:null}
        }));
      }
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers]);

  const languageChanger=(language)=>{
    if(!language||language==='en') return "English"
    if(language==='amh') return "አማርኛ"
    return "Oromiffa"

  }
  const [imageData, setImageData] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [userData, setUserData] = useState();

  const onClick = (rowData) => {
    setImageData(rowData.licenseImage);
    setProfileModal(true);
    setUserData(rowData);
  };


  useEffect(() => {
    setColumns([
      {
        title: t("first_name"),
        field: "firstName",
        initialEditValue: "",
        render: (rowData) => (
          <div style={{ color: !rowData.approved && "red" }}>
            {rowData.approved ? (
              <Link
                target="_blank"
                href={`/user/${rowData.id}?redirected=internal-reps`}
              >
                {rowData.firstName}
              </Link>
            ) : (
              rowData.firstName
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 50 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: t("last_name"),
        field: "lastName",
        initialEditValue: "",
        render: (rowData) => (
          <div style={{ color: !rowData.approved && "red" }}>
            {rowData.approved ? (
              <Link
                target="_blank"
                href={`/user/${rowData.id}?redirected=internal-reps`}
              >
                {rowData.lastName}
              </Link>
            ) : (
              rowData.lastName
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "Business Name",
        field: "shopeName",
        initialEditValue: "",
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "Language",
        field: "language",
        editable: "never",
        render: (rowData) => (
          <div >
            {rowData.language ? (
              <>
                <div>{languageChanger(rowData.language)}</div>
                <div style={{fontSize:11,opacity:0.7}}>{moment(rowData.languageUpdatedAt).fromNow()}</div>
              </>
            ) : (
               "English"
            )}
          </div>
        ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "Address",
        field: "city",
        initialEditValue: "",
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
        },
      },
      {
        title: "About Business",
        field: "description",
        initialEditValue: "",
        render: (rowData) =>(
          <div style={{lineBreak:"anywhere",whiteSpace:"break-spaces"}}>{rowData.description}</div>
        ),
        editComponent: props => (
          <Box>
              {props?.rowData?.shopeName ?  <TextField
                placeholder="Description"
                value={props.value}
                minRows={3}
                maxRows={5}
                multiline
                onChange={e => {
                  const newValue = e.target.value
                  props.onChange(newValue)
                }}
              />:
              null}
          </Box>
          ),
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 350,
          minWidth: 350,
        },
      },
      {
        title: t("email"),
        field: "email",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
        headerStyle: { textAlign: "center",  width: 150,
        minWidth: 150, },
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 150,
          minWidth: 150,
          textAlign: "center"
        },
      },
      {
        title: t("mobile"),
        field: "mobile",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
      {
        title: "Telegram Username",
        field: "TelegramId",
        editable: "never",
        render: (rowData) => {
          return (
            <div>
              {rowData?.TelegramId?.username ? (
                <a
                  target="_blank"
                  href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}
                >
                  {rowData?.TelegramId.username}
                </a>
              ) : (
                "Not Added"
              )}
            </div>
          );
        },
        cellStyle: {
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
        headerStyle: {
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
      },
      {
        title: "Profile Image",
        editable: "never",
        field: "profile_image",
        render: (rowData) =>
          rowData.profile_image ? (
            <img
              alt="profile_image"
              src={rowData.profile_image}
              style={{ width: 100 }}
            />
          ) : (
            "-"
          ),
        cellStyle: {
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
        headerStyle: {
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
        },
      },
      {
        title: t("lisence_image"),
        editable: "onUpdate",
        field: "licenseImage",
        render: (rowData) =>
          // <button onClick={() => { onClick(rowData, rowData.licenseImage) }}>
          rowData.licenseImage ? (
            <img
              alt="License"
              src={rowData.licenseImage}
              style={{ width: 100 }}
            />
          ) : (
            "Add"
          ),
          // </button>
        editComponent: (props) => (
          <button
            onClick={() => {
              onClick(props.rowData);
            }}
          >
            {props.rowData.licenseImage ? (
              <img
                alt="License"
                src={props.rowData.licenseImage}
                style={{ width: 100 }}
              />
            ) : (
              "Add"
            )}
          </button>
        ),
        cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            // backgroundColor: "#0e7c8c",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            zIndex: 2
          },
        headerStyle: {
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          zIndex: 2
        },
      },
      {
        title: "Major Customer",
        field: "isMajor",
        type: "boolean",
        render: (rowData) =>(
          <div style={{lineBreak:"anywhere",whiteSpace:"break-spaces"}}>{rowData.isMajor?"Yes":"No"}</div>
        ),
        initialEditValue: true,
        cellStyle: { textAlign: "center" },
        headerStyle:{textAlign:"center"}  

      },
      {
        title: "Account Status",
        field: "approved",
        type: "boolean",
        initialEditValue: true,
        cellStyle: { textAlign: "center" },
      },
      {
        title: "Telegram Verified",
        field: "emailVerified",
        type: "boolean",
        editable: "never",
        cellStyle: { textAlign: "center" },
      },
      {
        title: t("createdAt"),
        field: "createdAt",
        editable: "never",
        defaultSort: "desc",
        render: (rowData) =>
          rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
        cellStyle: {
          paddingLeft: isRTL === "rtl" ? 30 : 15,
          width: 180,
          minWidth: 180,
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (auth.info.profile.usertype === "admin") {
      setColumns((prevColumns) => [
        ...prevColumns,
        {
          title: "Owner Name",
          field: "ownerName",
          editable: "never",
          render: (rowData) => (rowData.ownerName ? rowData.ownerName : ""),
          cellStyle: {
            paddingLeft: isRTL === "rtl" ? 50 : 15,
            width: 150,
            minWidth: 150,
          },
        },
        {
          title: "Owner Email",
          field: "ownerEmail",
          editable: "never",
          render: (rowData) => (rowData.ownerEmail ? rowData.ownerEmail : ""),
          cellStyle: {
            paddingLeft: isRTL === "rtl" ? 50 : 15,
            width: 150,
            minWidth: 150,
          },
        },
      ]);
    } else return;
  }, [auth.info.profile.usertype]);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [viaBot, setViaBot] = useState(false);
  const [sendTo, setSendTo] = useState("all");
  const [viaChannel, setviaChannel] = useState(true);
  const [customerCombo, setCustomerCombo] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [commonAlert, setCommonAlert] = useState({ flag: false, msg: null });
  useEffect(() => {
    if (data && data.length > 0) {
      const filteredData = data
        .filter((e) => e.TelegramId?.id)
        .map((e) => {
          return {
            uid: e.id,
            desc: e.firstName + " " + e.lastName + " (" + e.shopeName + ") ",
            ...e,
          };
        });
      setCustomers(filteredData);
    }
  }, [data]);

  const [loadingSend, setLoadingSend] = useState(false);

  const handleSendMessage = () => {
    const channelId =
      auth?.info?.profile?.usertype === "fleetadmin"
        ? auth?.info?.profile?.channelId
        : giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.channelId;
    if (sendTo === "all" && !viaBot && !viaChannel) {
      setCommonAlert({ flag: true, msg: "Atleast one medium is mandatory" });
      return;
    }
    if (!channelId && viaChannel && sendTo === "all") {
      setCommonAlert({
        flag: true,
        msg: "Pleae go to your profile and set your channel id",
      });
      return;
    }
    if (!message) {
      setCommonAlert({ flag: true, msg: "Message is required" });
      return;
    }
    if (sendTo === "individual" && !customerCombo) {
      setCommonAlert({ flag: true, msg: "Customer is required" });
      return;
    }
    const data_tosend = {
      message,
      sendTo,
    };
    if (sendTo === "individual") {
      data_tosend["customer"] = customerCombo;
    } else {
      data_tosend["viaBot"] = viaBot;
      data_tosend["viaChannel"] = viaChannel;
      data_tosend["channelId"] = channelId;
      data_tosend["allCustomers"] = customers;
    }

    setLoadingSend(true);
    dispatch(api.seendTelegramNotification(data_tosend))
      .then((res) => {
        setCommonAlert({ flag: true, msg: res?.msg });
        setMessage("");
        setCustomerCombo(null);
        setOpenModal(false);
        setLoadingSend(false);
      })
      .catch((error) => {
        setCommonAlert({ flag: true, msg: error?.message });
        setLoadingSend(false);
      });
  };

  const handleChangeBot = (event) => {
    setViaBot(event.target.checked);
  };
  const handleChangeChannel = (event) => {
    setviaChannel(event.target.checked);
  };

  const pageBackgroundColor = () => {
    if (auth?.info?.profile?.dashboardColor)
      return auth?.info?.profile?.dashboardColor;
    if (
      giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    )
      return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
        ?.dashboardColor;
    return "#01aac1";
  };
  const handleCommonAlertClose = () => {
    setCommonAlert({ flag: false, msg: null });
  };

  const handleSetProfileModal = (e) => {
    e.preventDefault();
    if (selectedImage&&userData) {
      setLoading(true);
      dispatch(updateLicenseImage(userData, selectedImage));
      setProfileModal(false);
      setUserData(null)
     // setSelectedImage(null);
      setTimeout(() => {
        setLoading(false);
        dispatch(fetchUsersOnce());
      }, 10000);
    } else {
      setCommonAlert({ open: true, msg: t("choose_image_first") });
    }
  };

  const handleProfileModal = (e) => {
    setProfileModal(false);
   // setSelectedImage(null);
  };

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <div>
      <div style={{ marginBottom: 10, textAlign: "end", width: "100%" }}>
        <MuiButton
          sx={{
            background: pageBackgroundColor(),
            color: "white",
            "&:hover": { background: pageBackgroundColor() },
          }}
          onClick={() => setOpenModal(true)}
        >
          Send Notification
        </MuiButton>
      </div>

      <MaterialTable
        title={"manage Customers"}
        columns={columns}
        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        data={data}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            labelRowsSelect: t("rows"),
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              if (
                !newData.email ||
                !isValidEmail(newData.email) ||
                !newData.mobile
              ) {
                alert(t("email_or_mobile_issue"));
                reject();
              } else {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t("user_exists"));
                    reject();
                  } else if (res.error) {
                    alert(t("email_or_mobile_issue"));
                    reject();
                  } else if (
                    !auth.info.profile?.specialAdmin &&
                    !giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
                      ?.specialAdmin
                  ) {
                    alert("This feature is only for speacial admins");
                    reject();
                  } else if (auth.info.profile.usertype !== "admin") {
                    newData["regType"] = "admin";
                    newData["usertype"] = "customer";
                    newData["licenseImage"] = "";
                    newData["createdAt"] = new Date().toISOString();
                    if (auth?.info?.profile?.specialAdmin)
                      newData["carrierId"] = auth.info.uid;
                    else newData["carrierId"] = auth?.info?.profile?.carrierId;

                    dispatch(addUser(newData));
                    setTimeout(() => {
                      dispatch(fetchUsersOnce());
                      resolve();
                    }, 400);
                  } else {
                    resolve();
                  }
                });
              }
            }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                if (auth.info.profile.usertype !== "admin") {
                  // remove tableData via destructuring
                  const { tableData, ...cleanData } = newData;
            
                  dispatch(editUser(oldData.id, cleanData));
                  setTimeout(() => {
                    dispatch(fetchUsersOnce());
                    resolve();
                  }, 400);
                } else {
                  resolve();
                }
              }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  if (auth.info.profile.usertype !== "admin") {
                    dispatch(deleteUser(oldData.id));
                    setTimeout(() => {
                      dispatch(fetchUsersOnce());
                      resolve();
                    }, 400);
                  } else resolve();
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(t("demo_mode"));
                  }, 600);
                }),
        }}
      />
      <AlertDialog open={commonAlert.flag} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openModal}
        onClose={() => setOpenModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Box spacing={1} className={classes.paper}>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            Send Telegram Message
          </Typography>
          {/* <Box>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={"Title"}
                                onChange={(e)=>setTitle(e.target.value)}
                                value={title}
                                className={isRTL === "rtl" ? classes.inputRtl : null}
                                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                                placeholder='Title'
                              />
                      </Box> */}
          <Box style={{ width: "100%" }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={3}
              label={"Message"}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              placeholder="Message"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Send To
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={sendTo}
                onChange={(e) => setSendTo(e.target.value)}
                row
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label="Individual"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {sendTo === "individual" && (
            <Box style={{ width: "100%", marginTop: 6 }}>
              <Grid item xs={12}>
                {customers && customers.length > 0 ? (
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Select Customer"}
                    users={customers}
                    value={customerCombo}
                    onChange={(event, newValue) => {
                      setCustomerCombo(newValue);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: 17,
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    Customer Not Found
                  </div>
                )}
              </Grid>
            </Box>
          )}

          {sendTo === "all" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={viaChannel}
                      onChange={handleChangeChannel}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Via Channel"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={viaBot}
                      onChange={handleChangeBot}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Via Bot"
                />
              </Box>
            </Box>
          )}

          <Box sx={{ width: "100%", marginTop: "15px" }}>
            {loadingSend ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <MuiButton
                onClick={handleSendMessage}
                sx={{
                  width: "100%",
                  background: pageBackgroundColor(),
                  color: "white",
                  "&:hover": { background: pageBackgroundColor() },
                }}
              >
                Send
              </MuiButton>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
              {t("lisence_image")}
              <input
                type="file"
                style={{ marginLeft: 10 }}
                name={t("image")}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage && !loading ? (
              <Tooltip title={t("cancel")}>
                <CancelIcon
                  onClick={() => setSelectedImage(null)}
                  style={{
                    fontSize: 30,
                    backgroundColor: "red",
                    borderRadius: 50,
                    color: "white",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage ? (
              <img
                alt="not fount"
                height={"200px"}
                src={URL.createObjectURL(selectedImage)}
                style={{ marginTop: 15, marginBottom: 20, width: '100%', objectFit: 'contain' }} 
                />
            ) : imageData?(
              <img
                alt="licimage"
                height={"200px"}
                src={imageData}
                style={{ marginTop: 10, width: '100%', objectFit: 'contain' }}
              />
              ):null
          }
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleProfileModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetProfileModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>

    </div>
  );
}
