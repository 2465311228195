import React, {useRef} from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import LandingPage from "./views/LandingPage.js";
import LoginPage from "./views/LoginPage.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import AboutUs from "./views/AboutUs";
import AuthLoading from './views/AuthLoading';
import { Provider, useSelector } from "react-redux";
import ProtectedRoute from './views/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import Dashboard from './views/Dashboard';
import TrailerTypes from './views/TrailerTypes';
import RouteTypes from './views/routeTypes.js';
import AddBookings from './views/AddBookings';
import Shippers from './views/Shippers';
import Drivers from './views/Drivers';
import FleetAdmins from './views/FleetAdmins';
import Dispatchers from './views/Dispatchers.js';
import SharedBroker from './views/SharedBroker.js';
import SharedCarriers from './views/SharedCarriers.js';
import Notifications from './views/Notifications';
import Settings from './views/Settings';
import LanguageSetting from "./views/LanguageSetting";
import AppInformation from "./views/AppInformation";
import CancellationReasons from './views/CancellationReasons';
import { FirebaseProvider, store } from "common";
import { FirebaseConfig } from './config/FirebaseConfig';
import { GoogleMapApiConfig } from './config/GoogleMapApiConfig';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import CreateAdmin from './views/CreateAdmin';
import ContactUs from "./views/ContactUs";
import Trucking from "./views/Trucking.js";
import EachUserProfile from "./views/EachUserProfile.js";
import Customers  from "./views/customers.js"
import MyRoutes  from "./views/MyRoutes.js"
import LoadBoard from "./views/LoadBoard.js";
import CarrierPrivateBoard from "./views/CarrierPrivateBoard.js";
import PublicLoadBoard from "./views/PublicLoadBoard.js";
import TruckBoardDatas from "./views/TruckBoardDatas.js";
import DispatcherCompany from "./views/DispatcherCompany.js"
import SharedTracking from "./views/sharedTracking.js"
import { Redirect } from "react-router-dom/cjs/react-router-dom.min.js";
import StoreLocation from "views/StoreLocation.js";

i18n
.use(initReactI18next) 
.init({
    resources: {},
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
        escapeValue: false
    }
});

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

var hist = createBrowserHistory();

function App() {
  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current && !window.google) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=' + GoogleMapApiConfig + '&libraries=geometry,drawing,places',
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  // const excludedPaths = ["/contact-us", "/about", "/privacy-policy","/login"]; // Paths to exclude from authentication
  const excludedPaths = ["/login"]; // Paths to exclude from authentication
  return (
    <Provider store={store}>
      <FirebaseProvider config={FirebaseConfig}>
        <AuthLoading excludedPaths={excludedPaths}>
          <Router history={hist}>
            <Switch>
              <ProtectedRoute exact component={BookingHistory} path="/bookings" permit={"shipper,admin,driver,fleetadmin,dispatcher"} />
              <ProtectedRoute exact component={TruckBoardDatas} path="/truckboard" permit={"shipper,admin,driver,fleetadmin,dispatcher"} />
              <ProtectedRoute exact component={PublicLoadBoard} path="/loadboard" permit={"admin,fleetadmin,driver,shipper,dispatcher"} />

              <ProtectedRoute exact component={MyProfile} path="/profile" permit={"shipper,admin,driver,fleetadmin,dispatcher"} />
              <ProtectedRoute exact component={Dashboard} path="/fleetmap" permit={"fleetadmin,dispatcher,shipper"} />
              
              <ProtectedRoute exact component={LoadBoard} path="/my-board" permit={"admin,shipper,dispatcher-broker"} />
              <ProtectedRoute exact component={CarrierPrivateBoard} path="/private-board" permit={"admin,fleetadmin,dispatcher-independent,dispatcher-third,dispatcher-carrier"} />
              <ProtectedRoute exact component={DispatcherCompany} path="/dispatch-agencies" permit={"admin,fleetadmin,shipper"} />

              <ProtectedRoute exact component={TrailerTypes} path="/Trailertypes" permit={"admin"} />
              <ProtectedRoute exact component={RouteTypes} path="/routeTypes" permit={"admin"} />
              <ProtectedRoute exact component={CancellationReasons} path="/cancelreasons" permit={"admin"} />
              <ProtectedRoute exact component={AddBookings} path="/addbookings" permit={"admin,fleetadmin,dispatcher-independent,dispatcher-third,dispatcher-carrier"} />
              <ProtectedRoute exact component={Shippers} path="/shippers" permit={"admin"} />
              <ProtectedRoute exact component={Drivers} path="/drivers" permit={"admin,fleetadmin,dispatcher-independent,dispatcher-third,dispatcher-carrier"} />
              <ProtectedRoute exact component={CreateAdmin} path="/alladmin" permit={"admin"} />
              <ProtectedRoute exact component={FleetAdmins} path="/fleetadmins" permit={"admin"} />
              <ProtectedRoute exact component={Dispatchers} path="/internal-reps" permit={"admin,fleetadmin,dispatcher-independent,shipper"} />
              <ProtectedRoute exact component={SharedBroker} path="/my-brokers" permit={"fleetadmin,dispatcher-independent,"} />
              <ProtectedRoute exact component={SharedCarriers} path="/my-carriers" permit={"shipper,dispatcher-independent"} />
              <ProtectedRoute exact component={Trucking} path="/active-booking/:BookingId" permit={"driver,shipper,admin,fleetadmin,dispatcher"} />
              <ProtectedRoute exact component={EachUserProfile} path="/user/:userId" permit={"driver,shipper,admin,fleetadmin,dispatcher"} />

              <ProtectedRoute exact component={Customers} path="/customers" permit={"fleetadmin-special,dispatcher-carrier"} />
              <ProtectedRoute exact component={MyRoutes} path="/my-routes" permit={"fleetadmin-special"} />
              <ProtectedRoute exact component={StoreLocation} path="/my-stores" permit={"fleetadmin-special"} />

              <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin"} />
              <ProtectedRoute exact component={Settings} path="/settings" permit={"admin"} />
              <ProtectedRoute exact component={AppInformation} path="/appinfo" permit={"admin"} />
              <ProtectedRoute exact component={LanguageSetting} path="/languagesetting" permit={"admin"} />    

              <Route path="/freight-tracking/:BookingId" component={SharedTracking} />   
                            
              {/* <Route path="/contact-us" component={ContactUs} />                 
              <Route path="/about" component={AboutUs} />
              <Route path="/privacy-policy" component={PrivacyPolicy} /> */}
              <Route path="/login" component={LoginPage} />
              {/* <Route path="/" component={LandingPage} /> */}
              <Route exact path="/">
                <Redirect to="/fleetmap" />
              </Route>

              <Route>
                <Redirect to="/fleetmap" />
              </Route>
              
            </Switch>
          </Router>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;   