import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { makeStyles } from "@material-ui/core/styles";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";

import { Link, useMediaQuery,Button as MuButton } from "@material-ui/core";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slick";
import ArrowForward from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import UsersCombo from "components/UsersCombo";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Box, FormControlLabel, Input, Switch, TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import {InputLabel,MenuItem,FormControl,Select,IconButton,Typography,Modal,Grid} from "@mui/material";
import EastIcon from '@mui/icons-material/East';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: "#de7d1e",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    lineBreak: "anywhere",
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
    width: "60%",
    maxWidth: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "@media (max-width: 800px)": { width: "90%" },
    maxHeight: "70vh",
    overflow: "auto",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    fetchUsersOnce,
    updateLicenseImage,
    allCarriersDriverLocation,
    fetchRoutes,
    fetchGPSTrackers,
    editGPSTracker
  } = api;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [trailers, setTrailers] = useState({});
  const staticusers = useSelector((state) => state.usersdata.users);

  const trailertypes = useSelector((state) => state.trailertypes);
  const auth = useSelector((state) => state.auth);
  const locationdata = useSelector((state) => state.locationdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const loaded = useRef(false);
  const classes = useStyles();
  const rootRef = useRef(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [frequentRouteratePerMile, setFrequentRouteratePerMile] = useState(null);
  const [baseCity, setBaseCity] = useState(null);
  const [location, setLocation] = useState(null);
  const [emptyRoute, setemptyRoute] = useState(null);
  const [trailerTypeToEmptyRoute, setTrailerTypeToEmptyRoute] = useState(null);
  const [driverInfoToEmptyRoute, setDriverInfoToEmptyRoute] = useState(null);
  const [frequentRouteDates, setFrequentRouteDates] = useState(null);
  const [returnHomeDate, setReturnHomeDate] = useState(null);
  const [rowToUpdate,setRowToUpdate]=useState({})

  const [profileModal, setProfileModal] = useState(false);
  const [frequentRouteModal, setFrequentRouteModal] = useState(false);
  const [frequentRouteDateModal, setFrequentRouteDateModal] = useState(false);
  const [emptyRouteModal, setEmptyRouteModal] = useState(false);
  const [homeBaseModal, setHomeBaseModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [returnHomeDateModal, setReturnHomeDateModal] = useState(false);
  const [userData, setUserData] = useState();
  const [routeModal, setRouteModal] = useState(false);
  const [workingRoute, setWorkingRoute] = useState([]);
  const routes = useSelector((state) => state.routes);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [SharedCariers,SetSharedCarriers]=useState([])
  const [ActiveSharedCarrier,SetActiveSharedCarrier]=useState(null)  

  const gpstrackersdata = useSelector(state => state.gpstrackersdata);
  const theme = useTheme();

  function getStyles(name, workingRoute, theme) {
    return {
      fontWeight:
        workingRoute.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  
  function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
  }

  const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    return "#01aac1"
}

useEffect(() => {
  if (auth?.info?.profile?.usertype === "fleetadmin") {
    dispatch(fetchRoutes(auth.info.uid));
  } else dispatch(fetchRoutes(auth.info.profile?.carrierId));
}, [dispatch, fetchRoutes, auth]);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (routes.routes && routes.routes.length > 0) {
      setAvailableRoutes(routes.routes.filter((e) => e.isActive));
    }
  }, [routes]);


  useEffect(() => {
    dispatch(fetchGPSTrackers());
    
}, [dispatch, fetchGPSTrackers]);


   // Create gpsTrackerLookup for Material Table lookup
   const gpsTrackerLookup = useMemo(() => {
    const lookup = gpstrackersdata?.trackers.reduce((acc, gps) => {
        acc[gps.id] = gps.id; // Or device.name if available
        return acc;
    }, { "default": "None" }); // Add a default value here
    
    return lookup;
}, [gpstrackersdata?.trackers]);
  
    //fetch all drivers location
    const  [allDriversLocation,setAllDriversLocation]  = useState([])

   useEffect(() => {
    if(data.length>0){
       const filteredDriversData=data.map(e=>{
          return {id:e.id,gpsTracker:e.gpsTracker,trackigType:e?.trackigType}
       })
       dispatch(allCarriersDriverLocation(filteredDriversData));
    }

   }, [dispatch,auth,data])

   
   useEffect(()=>{
      const validDriverLocations=[]
      if(locationdata?.fetchDriverLocation&&locationdata?.fetchDriverLocation?.length>0){
        validDriverLocations.push(...locationdata?.fetchDriverLocation)
      }
      if(locationdata?.fetchDriverGpsLocation&&locationdata?.fetchDriverGpsLocation?.length>0){
        validDriverLocations.push(...locationdata?.fetchDriverGpsLocation)
      }
      setAllDriversLocation(validDriverLocations)
   },[locationdata])

 
  useEffect(() => {
    if (staticusers) {
      setData(
        staticusers.filter(
          (user) =>
            user.usertype === "driver" &&
            ((user.fleetadmin === auth.info.uid &&
              auth.info.profile.usertype === "fleetadmin") ||
              auth.info.profile.usertype === "admin"||
             ((isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&user.fleetadmin===ActiveSharedCarrier?.id)||
             (user.fleetadmin=== auth.info.profile.carrierId&&auth.info.profile.usertype === "dispatcher"))
        )
      );
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers, auth.info.profile.usertype, auth.info.uid,ActiveSharedCarrier]);

  const emptyRoutePostinglistdata = useSelector(
    (state) => state.emptyRoutePostinglistdata.emptyRoutePostings
  );

  const [generalTabelData, setGeneralTabelData] = useState([]);

  useEffect(()=>{
    const combinedDataMap = [];
    for (const driver of data) {
      let getEmptyRouteOfDriver = emptyRoutePostinglistdata?.filter((e) => e.driver === driver.id);
      let eachDriverData={...driver}
      if (getEmptyRouteOfDriver&&getEmptyRouteOfDriver?.length > 0) {
        eachDriverData["emptyRoute"]=getEmptyRouteOfDriver
      }
      else eachDriverData["emptyRoute"]=[]
      combinedDataMap.push(eachDriverData);
    }
    setGeneralTabelData(combinedDataMap.length==0?[]:combinedDataMap.map(e=>{
        return {...e,tableData:null}
      })
    );
  },[data,emptyRoutePostinglistdata])


  useEffect(() => {
    if (trailertypes.trailers) {
      let obj = {};
      trailertypes.trailers.map(
        (trailer) => (obj[trailer.name] = trailer.name)
      );
      setTrailers(obj);
    }
  }, [trailertypes.trailers]);

  const onClick = (rowData) => {
    setImageData(rowData.licenseImage);
    setProfileModal(true);

    setUserData(rowData);
  };

  const targetRoute = (workingRoute) => {
    if (!workingRoute || availableRoutes.length === 0) return null;
    const route = availableRoutes.filter((e) => {
      return e.identification === workingRoute;
    });
    return route.length > 0 ? route[0] : null;
  };

  const onlyActiveRoutes = (allRoutes) => {
    if (!allRoutes || allRoutes.length == 0) return [];
    const filteredRoutes = allRoutes.filter((e) => {
      return targetRoute(e)?.isActive;
    });

    return filteredRoutes;
  };

  const handleRouteModal = (e) => {
    setRouteModal(false);
    setUserData(null);
    //setBaseCity(null)
  };

  const handleSetRouteModal = (e) => {
    e.preventDefault();
    if (workingRoute) {
      setLoading(true);

      if (userData) {
        dispatch(
          editUser(
            userData.id,
            { ...userData, workingRoute: workingRoute },
            userData
          )
        );
        setTimeout(() => {
          dispatch(fetchUsersOnce());
        }, 1000);
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Return Home Date" });
    }

    setLoading(false);
    handleRouteModal();
  };
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setWorkingRoute(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const openRouteModal = (rowData) => {
    setUserData(rowData);

    if (!workingRoute || rowToUpdate?.workingRoute !== rowData.id) {
      setWorkingRoute(rowData.workingRoute || []);
    }
    if (rowData) setRowToUpdate((e) => ({ ...e, workingRoute: rowData.id }));
    setRouteModal(true);
  };

  const openFrequentRouteModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    setUserData(rowData);
    if(rowData?.id) {
        setRowToUpdate(e => ({ ...e, dropAddress: rowData.id }));  
        setRowToUpdate(e => ({ ...e, pickupAddress: rowData.id }));        
        setRowToUpdate(e => ({ ...e, frequentRouteratePerMile: rowData.id }));        

    }

    if(!pickupAddress||rowToUpdate?.pickupAddress!==rowData.id){
      setPickupAddress(
        rowData.frequentRoute
          ? {
              coords: {
                lat: rowData.frequentRoute.cityOne.lat,
                lng: rowData.frequentRoute.cityOne.lng,
              },
              description: rowData.frequentRoute.cityOne.add,
            }
          : ""
      );
    }
    
    if(!dropAddress||rowToUpdate?.dropAddress!==rowData.id){
        setDropAddress(
          rowData.frequentRoute
            ? {
                coords: {
                  lat: rowData.frequentRoute.cityTwo.lat,
                  lng: rowData.frequentRoute.cityTwo.lng,
                },
                description: rowData.frequentRoute.cityTwo.add,
              }
            : ""
        );
    }

    if(!frequentRouteratePerMile||rowToUpdate?.frequentRouteratePerMile!==rowData.id){
      setFrequentRouteratePerMile(rowData.frequentRouteratePerMile ? rowData.frequentRouteratePerMile: null);
  }

    // Open the modal
    setFrequentRouteModal(true);
  };

  const openFrequentRouteDateModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    setUserData(rowData);
    if(rowData?.id) setRowToUpdate(e => ({ ...e, frequentRouteDates: rowData.id })); 
   if(!frequentRouteDates||rowToUpdate?.frequentRouteDates!==rowData.id){
      setFrequentRouteDates(rowData.frequentRouteDates ? rowData.frequentRouteDates : []);
   }
   

    // Open the modal
    setFrequentRouteDateModal(true);
  };

  const [driverNotVerifiedText,setDriverNotVerifiedText]=useState(false)
  
  const openEmptyRouteModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    if(!rowData.emailVerified) setDriverNotVerifiedText(true)
    else{
      setDriverNotVerifiedText(false)
            setUserData(rowData);
            let data={
              id:rowData.id,
              TelegramId:rowData.TelegramId,
              email:rowData.email,
              firstName:rowData.firstName,
              lastName:rowData.lastName,
              mobile:rowData.mobile,
              profile_image:rowData.profile_image,
              pushToken:rowData.pushToken
            }
          if(rowData) setRowToUpdate(e => ({ ...e, emptyRoute: rowData.id })); 
          if(!emptyRoute||rowToUpdate?.emptyRoute!==rowData.id||(emptyRoute.length>0&&!emptyRoute[0].id&&rowData.emptyRoute.length===emptyRoute.length)){
              setemptyRoute(rowData.emptyRoute ? rowData.emptyRoute : []);
              setTrailerTypeToEmptyRoute(rowData.trailerType)
              setDriverInfoToEmptyRoute(data)
          }
            setEmptyRouteModal(true);
     }
  }


  const openHomeBaseModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    setUserData(rowData);
    if(!baseCity||rowToUpdate?.baseCity!==rowData.id){
      setBaseCity(rowData.baseCity
        ? {
            coords: { lat: rowData.baseCity.lat, lng: rowData.baseCity.lng },
            description: rowData.baseCity.add,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, baseCity: rowData.id })); 

    // Open the modal
    setHomeBaseModal(true);
  };

  const openLocationModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    setUserData(rowData);
    if(!location||rowToUpdate?.location!==rowData.id){
      setLocation(rowData.location
        ? {
            coords: { lat: rowData.location.lat, lng: rowData.location.lng },
            description: rowData.location.add,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, location: rowData.id })); 

    // Open the modal
    setLocationModal(true);
  };

  const openReturnHomeDateModal = (rowData) => {
    // Set the initial values for the modal based on the frequentRoute data
    setUserData(rowData);
    if(!returnHomeDate||rowToUpdate?.returnHomeDate!==rowData?.id){
      setReturnHomeDate( rowData.returnHomeDate? rowData.returnHomeDate: null
      );
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, returnHomeDate: rowData.id })); 

    // Open the modal
    setReturnHomeDateModal(true);
  };

  const daysOfWeek = [
    { label: "Sun", value: "Sunday" },
    { label: "Mon", value: "Monday" },
    { label: "Tue", value: "Tuesday" },
    { label: "Wed", value: "Wednesday" },
    { label: "Thu", value: "Thursday" },
    { label: "Fri", value: "Friday" },
    { label: "Sat", value: "Saturday" },
  ];

  const toggleDaySelection = (day) => {
    if (frequentRouteDates?.includes(day)) {
      setFrequentRouteDates(
        frequentRouteDates?.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setFrequentRouteDates([...frequentRouteDates, day]);
    }
  };

  const handleDateSelect = (date) => {
    if(new Date(date).getTime()>new Date().getTime()){
          setReturnHomeDate(date);
    }
  };

  const smallScreen = useMediaQuery('(max-width: 940px)')

  const isUsingMile=(countryCode)=>{
     if(countryCode === 'US') return true
     return false
  }

  const [driverTrackingType, setDriverTrackingDevice] =useState(null)

  useEffect(()=>{
    if(auth.info?.profile?.selectedcountry?.code === 'US'){
      setDriverTrackingDevice({
            mobile: auth.info?.profile?.trackigType === 'mobile'? 'Default': 'Mobile App GPS',
            eld: auth.info?.profile?.trackigType === 'eld'?'Default':'ELD Device',
            select: auth.info?.profile?.trackigType === 'select'?'Default':'Not Set',
            asset: auth.info?.profile?.trackigType === 'asset'?'Default':'Asset Tracker',

          })
    }
    else{
      setDriverTrackingDevice({
        mobile: auth.info?.profile?.trackigType === 'mobile'? 'Default': 'Mobile App GPS',
        fleetDevice: auth.info?.profile?.trackigType === 'fleetDevice' ? 'Default' : 'Gps Asset Tracker',
        select: auth.info?.profile?.trackigType === 'select'?'Default':'Not Set'
      })

    }


},[auth])

   

const showSelectedTrackingMethod = (selectedTracking) =>{
  
   if(auth.info?.profile?.trackigType !=='select' && auth.info?.profile?.trackigType&& auth.info?.profile?.trackigType === selectedTracking) return 'Default'
   return driverTrackingType[selectedTracking]
   
}

const [columns,setColumns] =useState([])

const isSpecialAdmin=()=>{
     
  return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
}

const driverLocation = (driverId)=>{
  if(!allDriversLocation||allDriversLocation.length===0) return  null

  const filteredLocation = allDriversLocation.filter(e=>{
     return e.id === driverId
  })
   if(filteredLocation.length === 0) return  null
    return filteredLocation[0]||null
}

// Function to construct the Google Maps URL
const getGoogleMapsLink = (lat, lng) => {
  const GOOGLE_MAPS_BASE_URL = "https://www.google.com/maps";
  return `${GOOGLE_MAPS_BASE_URL}?q=${lat},${lng}`;
};

const languageChanger=(language)=>{
  if(!language||language==='en') return "English"
  if(language==='amh') return "አማርኛ"
  return "Oromiffa"

}


useEffect(()=>{
   if(isSpecialAdmin()){
    setColumns([
      {
        title: t("first_name"),
        field: "firstName",
        initialEditValue: "",
        fixed: 'left',
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "center",
          width: 150,
          minWidth: 150,
          backgroundColor: "#E2F6F9",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'100px',
          zIndex: smallScreen ? 1 : 3
        },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'100px',
          top: '0',
          zIndex: smallScreen ? 1 : 13
        },
      },
      {
        title: t("last_name"),
        field: "lastName",
        initialEditValue: "",
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "center",
          width: 150,
          minWidth: 150,
          backgroundColor: "#E2F6F9",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'200px',
          zIndex: smallScreen ? 1 : 3
        },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'200px',
          zIndex: smallScreen ? 1 : 13
        },
      },
      // { title: "Other Info", field: 'other_info', initialEditValue: '', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
      //  },
      {
        title: "Language",
        field: "language",
        editable: "never",
        render: (rowData) => (
          <div>
            {rowData.language ? (
              <>
                <div>{languageChanger(rowData.language)}</div>
                <div style={{ fontSize: 11, opacity: 0.7 }}>
                  {moment(rowData.languageUpdatedAt).fromNow()}
                </div>
              </>
            ) : (
              "English"
            )}
          </div>
        ),
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "center",
          width: 150,
          minWidth: 150,
          backgroundColor: "#E2F6F9",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'200px',
          zIndex: smallScreen ? 1 : 3
        },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: smallScreen ? 'relative' : 'sticky',
          left: smallScreen ? '0px' :'200px',
          zIndex: smallScreen ? 1 : 13
        },
      },
      {
        title: "Current Location",
        field: "location",
        editable: "never",
        render: (rowData) =>
          (
            !locationdata?.loading?
              <div>
                {driverLocation(rowData?.id)?
                    <a  target="__blank" href={getGoogleMapsLink(driverLocation(rowData?.id).lat,driverLocation(rowData?.id).lng)}>
                       {driverLocation(rowData?.id)?.add}
                    </a>
                  :
                  <p>Missing Location</p>
                  }
              </div>
              :
            <CircularProgress size={15}/>
          ),
        cellStyle: {
          backgroundColor: "#D6F2F6",
          textAlign: isRTL === "rtl" ? "right" : "center",
          width: 150,
          minWidth: 150,
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: "Open For Duty",
        field: "driverActiveStatus",
        editable: "onUpdate",
        type: "boolean",
        initialEditValue: true,
        render: (rowData) =>
          rowData.driverActiveStatus ? (
                "yes"
            ) : (
              <div>
                   No
                  <div style={{fontSize:12,opacity:0.5}}>{moment(rowData?.lastseen).fromNow()}</div>
              </div>
            ),
         editComponent: props => (
          <Box>
              <FormControlLabel
                  control={
                    <Switch checked={props.value} onChange={event=>{
                      props.onChange(event.target.checked)
                    }} name="gilad" />
                  }
                  disabled={ !props.value&&(!props.rowData.mobileTokens || props?.rowData.mobileTokens.length===0)}
                  label=""
                />
               {(!props.rowData.mobileTokens || props?.rowData.mobileTokens.length===0)&&
                <Typography style={{fontSize:12,fontWeight:500}}>Driver not logged in</Typography>}
          </Box>
              ),
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#D6F2F6",
          width: 150,
          minWidth: 150,
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: "Working Route",
        field: "workingRoute",
        editable: "onUpdate",
        render: (rowData) => (
          <>
            {routes?.loading ? (
              <CircularProgress />
            ) : (
              <>
                {
                (onlyActiveRoutes(rowData.workingRoute) && onlyActiveRoutes(rowData.workingRoute).length > 0) ||
                (onlyActiveRoutes(workingRoute)&& rowToUpdate?.workingRoute === rowData?.id && onlyActiveRoutes(workingRoute).length > 0) ? (
                  <div>
                    {
                    (onlyActiveRoutes(workingRoute) && onlyActiveRoutes(workingRoute).length > 0
                      && rowToUpdate?.workingRoute === rowData?.id)?
                        onlyActiveRoutes(workingRoute).map((e, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                marginBottom: 4,
                                fontSize:13,
                                borderBottom: "1px solid black",
                              }}
                            >
                              {targetRoute(e)?.pickup?.description} to{" "}
                              {targetRoute(e)?.drop?.description} <span style={{fontWeight: 700,fontSize:14}}>({targetRoute(e)?.name})</span>
                            </div>
                          );
                         })
                        :
                      onlyActiveRoutes(rowData.workingRoute).map((e, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              marginBottom: 4,
                              fontSize:13,
                              borderBottom: "1px solid black",
                            }}
                          >
                            {targetRoute(e)?.pickup?.description} to{" "}
                            {targetRoute(e)?.drop?.description} <span style={{fontWeight: 700,fontSize:14}}>({targetRoute(e)?.name})</span>
                          </div>
                        );
                      })
                  }
                  </div>
                ) : (
                  <div>Not Assigned</div>
                )}
              </>
            )}
          </>
        ),
        editComponent: (props) => (
          <span>
            {routes?.loading ? (
              <CircularProgress />
            ) : (
              <Button
                style={{ background: pageBackgroundColor(), color: "white" }}
                onClick={() => openRouteModal(props.rowData)}
              >
                {onlyActiveRoutes(workingRoute) && onlyActiveRoutes(workingRoute).length > 0 &&
                 rowToUpdate?.workingRoute === props.rowData?.id ? (
                  <div>
                    {onlyActiveRoutes(workingRoute).map((e, index) => {
                      return (
                        <div
                          style={{
                            marginBottom: 4,
                            fontWeight: 700,
                            borderBottom: "1px solid black",
                          }}
                          key={index}
                        >
                          {targetRoute(e)?.name}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {onlyActiveRoutes(props?.rowData.workingRoute) &&
                    onlyActiveRoutes(props?.rowData.workingRoute).length > 0 ? (
                      <div>
                        {onlyActiveRoutes(props.rowData.workingRoute).map(
                          (e, index) => {
                            return (
                              <div
                                style={{
                                  marginBottom: 4,
                                  fontWeight: 700,
                                  borderBottom: "1px solid black",
                                }}
                                key={index}
                              >
                                {targetRoute(e)?.name}
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      "Add Route"
                    )}
                  </>
                )}
              </Button>
            )}
          </span>
        ),
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#D6F2F6",
          width: 200,
          minWidth: 200,
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2,
          textAlign:"center"
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2,
          textAlign:"center"
        },
      },

      {
        title: "Maxiumum Weight",
        field: "maximumWeight",
        initialEditValue: "",
        type: Number,
        render: (rowData) =>(
          <div>{rowData.maximumWeight?`${rowData.maximumWeight} Kg`:`0 Kg`}</div>
       ),
        cellStyle: {
          // backgroundColor: "#39a9ba",
          backgroundColor: '#D6F2F6',
          width: 150,
          minWidth: 150,
          textAlign: isRTL === "rtl" ? "right" : "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        editComponent: props => (
          <TextField
            placeholder="Maximum Weight"
            value={props.value}
            type='number'
            onChange={e => {
              const newValue = e.target.value
              if(Number(newValue)){
                props.onChange(newValue)
              }
              else props.onChange(0)
            }}
          />
        )
      },
      {
        title: "Loaded Weight",
        field: "availableWeight",
        initialEditValue: 0,
        type: Number,
        render: (rowData) =>(
          <div>{rowData.availableWeight?`${rowData.availableWeight} Kg`:`0 Kg`}</div>
       ),
        cellStyle: {
          // backgroundColor: "#39a9ba",
          backgroundColor: '#D6F2F6',
          width: 150,
          minWidth: 150,
          textAlign: isRTL === "rtl" ? "right" : "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        editComponent: props => (
          <TextField
            placeholder="Loaded Weight"
            value={props.value}
            disabled={!props?.rowData.maximumWeight}
            type='number'
            onChange={e => {
              const newValue = e.target.value
              if(Number(newValue)){
                 props.onChange(newValue)
              }
              else props.onChange(0)
            }}
          />
        )
      },
      {
        title: "Home City",
        field: "baseCity",
        editable: "onUpdate",
        render: (rowData) =>
        rowData.baseCity||baseCity?.description ? (
            <div>
              <p>{(baseCity?.description&&rowToUpdate?.baseCity===rowData.id)?baseCity?.description:rowData?.baseCity?.add}</p>
            </div>
          ) : (
            "Missing Home City"
          ),
        editComponent: (props) => (
          <button onClick={() => openHomeBaseModal(props.rowData)}>
            {props.rowData.baseCity||baseCity?.description ? (
              <div>
                 <p>{(baseCity?.description&&rowToUpdate?.baseCity===props.rowData.id)?baseCity?.description:props.rowData?.baseCity?.add}</p>
              </div>
            ) : (
              "Add"
            )}
          </button>
        ),
        cellStyle: {
          backgroundColor: "#D6F2F6",
          textAlign: isRTL === "rtl" ? "right" : "center",
          width: 150,
          minWidth: 150,
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: "Carrier Number",
        field: "vehicleNumber",
        initialEditValue: "",
        type: Number,
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 180,
          width: 180,
          backgroundColor: "#E2F6F9",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        editComponent: props => (
          <TextField
            type="text"
            placeholder="Truck Number"
            value={props.value}
            onChange={e => {
              const newValue = e.target.value
              props.onChange(newValue)
            }}
          />
        )
      },
      // {
      //   title: "Delivery In Progress",
      //   field: "queue",
      //   type: "boolean",
      //   render: (rowData) =><div>
      //       {!rowData.queue&&
      //         <div>
      //           <div>No Delivery in progress</div>
      //           {rowData.emailVerified&&rowData.approved&&
      //               <>
      //                 {(isSuperDispatcher()||auth.info?.profile?.dispatcherId)?
      //                     <Link href={`/addbookings?user=${rowData.id}&&carrier=${rowData.fleetadmin}`}><Button>Assign Manually</Button></Link>:
      //                    <Link href={`/addbookings?user=${rowData.id}`}><Button style={{backgroundColor:pageBackgroundColor()}}>Assign Manually</Button></Link>}
      //               </>
      //            }
      //         </div>
      //       }
      //        {rowData.queue&&<CheckIcon/>}
      //     </div>,
      //   initialEditValue: false,
      //   editable: "never",
      //   cellStyle: {
      //     fontWeight: "bold",
      //     textAlign: isRTL === "rtl" ? "right" : "center",
      //     backgroundColor: "#D6F2F6",
      //     width: 150,
      //     minWidth: 150,
      //     position: 'sticky',
      //     left: smallScreen ? '0px' :'350px',
      //     zIndex: 2
      //   },
      //   headerStyle: {
      //     fontWeight: "bold",
      //     backgroundColor: pageBackgroundColor(),
      //     color: "white",
      //     textAlign: "center",
      //     position: 'sticky',
      //     left: smallScreen ? '0px' :'350px',
      //     zIndex: 2
      //   },
      // },
      {
        title: t("mobile"),
        field: "mobile",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#C6E8EE",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: t("email"),
        field: "email",
        editable: "onAdd",
        render: (rowData) =>
          settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#C6E8EE",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title:'Telegram Username',
        field: "TelegramId",
        editable: "never",
        render: (rowData) =>{
          return(
              <div>{rowData?.TelegramId?.username?<a target='_blank' href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}>{rowData?.TelegramId.username}</a>:"Not Added"}</div>
          ) 
          },
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#C6E8EE",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: t("lisence_image"),
        editable: "onUpdate",
        field: "licenseImage",
        render: (rowData) =>
          // <button onClick={() => { onClick(rowData, rowData.licenseImage) }}>
          rowData.licenseImage ? (
            <img
              alt="License"
              src={rowData.licenseImage}
              style={{ width: 100 }}
            />
          ) : (
            "Add"
          ),
          // </button>
        editComponent: (props) => (
          <button
            onClick={() => {
              onClick(props.rowData);
            }}
          >
            {props.rowData.licenseImage ? (
              <img
                alt="License"
                src={props.rowData.licenseImage}
                style={{ width: 100 }}
              />
            ) : (
              "Add"
            )}
          </button>
        ),
        cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            // backgroundColor: "#0e7c8c",
            backgroundColor: '#C6E8EE',
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: t("profile_image"),
        field: "profile_image",
        render: (rowData) =>
          rowData.profile_image ? (
            <img
              alt="Profile"
              src={rowData.profile_image}
              style={{ width: 50, borderRadius: "50%" }}
            />
          ) : (
            "No Profile Picture"
          ),
        editable: "never",
        cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#C6E8EE",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        headerStyle: {
          backgroundColor: pageBackgroundColor(),
          fontWeight: "bold",
          color: "white",
          fontSize: 15,
          width: 200,
          minWidth: 200,
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: "Account Status",
        field: "approved",
        type: "boolean",
        initialEditValue: true,
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#92b3b8",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      { title: "Tracking Method",  
        field: 'trackigType',
        lookup: driverTrackingType, 
        editable: "onUpdate",
        render: (rowData) =>
          rowData.trackigType&& driverTrackingType ? (
             <span>{showSelectedTrackingMethod(rowData.trackigType)}</span>
          ) : (
            "No Set"
          ),
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#92b3b8",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      {
        title: "Assigned GPS",
        field: 'gpsTracker',
        lookup: gpsTrackerLookup,
        editable: "onUpdate",
        render: (rowData) =>
          !gpstrackersdata?.loading?
            rowData?.gpsTracker ? (
                <span>{rowData.gpsTracker}</span>
            ) : (
                "No Set"
            ):
            <CircularProgress size={15}/>
          ,
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
    },
      { title: "Registered Mobile",  
        field: 'registeredDeVicee',
        editable: "never",
        render: (rowData) =>
          rowData.mobileTokens && rowData.mobileTokens.length>0 ? (
             <div>
                {rowData.mobileTokens.map((e,index)=>{
                    return (
                    <div style={{marginBottom:7}}>
                          <div>{e.deviceName}</div>
                          <div style={{fontSize:13,fontWeight:400}}>Id: {e.deviceId}</div>
                          <div style={{fontSize:11,fontWeight:400,opacity:0.7}}>Joined at {moment(e.joinedDate).format('lll') }</div>
                    </div>
                    )
                })}
                <Button onClick={()=>clearMobileTokens(rowData)} style={{fontWeight:'bold',background:pageBackgroundColor()}}>Clear all Devices</Button>
             </div>
          ) : (
            "No Set"
          ),
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#92b3b8",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
      
      { title: "Telegram Verified",  field: 'emailVerified', type:'boolean',  editable:'never', 
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#92b3b8",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
    ])
   }
   else if(auth.info?.profile?.usertype === 'fleetadmin' && auth.info?.profile.SelectTrackingonOnDriverPage){
      setColumns([
        {
          title: t("first_name"),
          field: "firstName",
          initialEditValue: "",
          fixed: 'left',
          cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'100px',
            zIndex: smallScreen ? 1 : 3
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'100px',
            top: '0',
            zIndex: smallScreen ? 1 : 13
          },
        },
        {
          title: t("last_name"),
          field: "lastName",
          initialEditValue: "",
          cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 3
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 13
          },
        },
        {
          title: "Language",
          field: "language",
          editable: "never",
          render: (rowData) => (
            <div>
              {rowData.language ? (
                <>
                  <div>{languageChanger(rowData.language)}</div>
                  <div style={{ fontSize: 11, opacity: 0.7 }}>
                    {moment(rowData.languageUpdatedAt).fromNow()}
                  </div>
                </>
              ) : (
                "English"
              )}
            </div>
          ),
          cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 3
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 13
          },
        },
        // { title: "Other Info", field: 'other_info', initialEditValue: '', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
        //  },
        {
          title: "Current Location",
          field: "location",
          editable: "never",
          render: (rowData) =>
            (
              !locationdata?.loading?
                <div>
                  {driverLocation(rowData?.id)?
                      <a  target="__blank" href={getGoogleMapsLink(driverLocation(rowData?.id).lat,driverLocation(rowData?.id).lng)}>
                         {driverLocation(rowData?.id)?.add}
                      </a>
                    :
                    <p>Missing Location</p>
                    }
                </div>
                :
              <CircularProgress size={15}/>
            ),
          // editComponent: (props) => (
          //   <button onClick={() => openLocationModal(props.rowData)}>
          //     {props.rowData.location||location?.description ? (
          //       <div>
          //          <p>{(location?.description&&rowToUpdate?.location===props.rowData.id)?location?.description:props.rowData?.location?.add}</p>
          //       </div>
          //     ) : (
          //       "Add"
          //     )}
          //   </button>
          // ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Open For Duty",
          field: "driverActiveStatus",
          type: "boolean",
          initialEditValue: true,
          render: (rowData) =>
            rowData.driverActiveStatus ? (
                  "yes"
              ) : (
                "No"
              ),
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#D6F2F6",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Maxiumum Weight",
          field: "maximumWeight",
          initialEditValue: "",
          type: Number,
          render: (rowData) =>(
            <div>{rowData.maximumWeight?`${rowData.maximumWeight} Kg`:`0 Kg`}</div>
         ),
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Maximum Weight"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: "Loaded Weight",
          field: "availableWeight",
          initialEditValue: 0,
          type: Number,
          render: (rowData) =>(
            <div>{rowData.availableWeight?`${rowData.availableWeight} Kg`:`0 Kg`}</div>
         ),
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Loaded Weight"
              value={props.value}
              disabled={!props?.rowData.maximumWeight}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                   props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: "Travel Radius (Mile)",
          field: "travelRadius",
          initialEditValue: "",
          type: Number,
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Travel Radius"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: `Rate Per ${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`,
          field: "ratePerMile",
          initialEditValue: "",
          render: (rowData) =>(
             <div>{rowData.ratePerMile?`$${rowData.ratePerMile}/${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`:"Unknown"}</div>
          ),
          type: Number,
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Rate Per Mile"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else  props.onChange(0)
                
              }}
            />
          )
        },
        {
          title: "Preferred Lane",
          field: "frequentRoute",
          editable: "onUpdate",
          render: (rowData) =>
       
            (rowData.frequentRoute||(pickupAddress?.description&&dropAddress?.description)) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "6px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                  <div>{(pickupAddress?.description&&rowToUpdate?.pickupAddress===rowData.id)?pickupAddress?.description:rowData?.frequentRoute?.cityOne?.add} </div>
                  {((pickupAddress?.description&&rowToUpdate?.pickupAddress===rowData.id)||rowData?.frequentRoute?.cityOne?.add)&&
                  <div style={{ color: "red", width: "fit-content" }}>
                    <MultipleStopIcon />
                  </div>}
                  <div>{(dropAddress?.description&&rowToUpdate?.dropAddress===rowData.id)?dropAddress?.description:rowData?.frequentRoute?.cityTwo?.add}</div>
                </div>
    
                {((frequentRouteratePerMile&&rowToUpdate?.frequentRouteratePerMile===rowData.id)||rowData?.frequentRouteratePerMile)&&
                  <div  style={{marginTop:"5px",fontSize:"11px",fontWeight:"500"}}>
                     {/* <span style={{fontWeight:"500"}}>Rate Per {isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}: </span> */}
                     ${(frequentRouteratePerMile&&rowToUpdate?.frequentRouteratePerMile===rowData.id)?frequentRouteratePerMile:rowData?.frequentRouteratePerMile||0}/{isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"} 
                  </div>}
                
                 {(rowData?.frequentRouteDates?.length>0||frequentRouteDates) ? (
                      <div style={{fontSize:"11px",marginTop:"5px"}}>
                            <div style={{fontSize:"11px",fontWeight:"500"}}>Delivery Dates & Times</div>
                            {(frequentRouteDates&&frequentRouteDates?.length>0&&rowToUpdate?.frequentRouteDates===rowData.id)?
                            frequentRouteDates?.join(", "):
                              (rowData?.frequentRouteDates?.length>0?rowData?.frequentRouteDates?.join(", "): 
                               <span>No Delivery Dates & Times Found</span>
                              )
                            }
                      </div>
                    ) : (
                      <div style={{marginTop:"5px",fontSize:"11px"}}>No Delivery Dates & Times Found</div>
                    )}
              </>
            ) : (
              "Add"
            ),
            // </button>
          editComponent: (props) => (
            <span>
                <button onClick={() => openFrequentRouteModal(props.rowData)}>
                  {props.rowData.frequentRoute||(pickupAddress?.description&&dropAddress?.description) ? (
                    <div>
                      <p>City 1: {(pickupAddress?.description&&rowToUpdate?.pickupAddress===props.rowData.id)?pickupAddress?.description:props.rowData.frequentRoute?.cityOne?.add}</p>
                      <p>City 2: {(dropAddress?.description&&rowToUpdate?.dropAddress===props.rowData.id)?dropAddress?.description:props.rowData.frequentRoute?.cityTwo?.add}</p>
                      <div>Rate Per {isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}: {frequentRouteratePerMile?frequentRouteratePerMile:props.rowData.frequentRouteratePerMile}</div>
                    </div>
                  ) : (
                    "Add"
                  )}
                </button>
                
                <div  style={{marginTop:"20px"}}>
                  <button onClick={() => openFrequentRouteDateModal(props.rowData)}>
                    <div style={{textAlign:"center",marginBottom:"10px",fontWeight:"700"}} >Delivery Dates & Times</div>
                    {props.rowData.frequentRouteDates||(frequentRouteDates&&frequentRouteDates?.length>0)
                      ?
                      <>
                      {(frequentRouteDates&&frequentRouteDates?.length>0&&rowToUpdate?.frequentRouteDates===props.rowData.id)?
                        <>
                        {frequentRouteDates?.map((day) => (
                          <div
                            key={day.value}
                          >
                            <p
                              style={{
                                color: props?.rowData?.frequentRouteDates?.includes(day)
                                  ? pageBackgroundColor()
                                  : "black",
                              }}
                            >
                              {day}
                            </p>
                          </div>
                        ))}
                        </>
                      :
                      <>
                          {props.rowData.frequentRouteDates&&props.rowData.frequentRouteDates.length>0&&props.rowData.frequentRouteDates.map((day) => (
                            <div
                              key={day.value}
                            >
                              <p
                                style={{
                                  color: props.rowData.frequentRouteDates.includes(day)
                                    ? pageBackgroundColor()
                                    : "black",
                                }}
                              >
                                {day}
                              </p>
                            </div>
                          ))}
                        </>
                        }
                      </>
                      : "Add Delivery Dates & Times"}
                  </button>
    
                </div>
    
            </span>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            width: 400,
            minWidth: 400,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Empty Route Posts",
          field: "emptyRoute",
          editable: "onUpdate",
          render: (rowData) =>
            rowData.emptyRoute.length>0||(emptyRoute) ? (
              <div style={{width: 300}}>
                {(emptyRoute&&rowToUpdate?.emptyRoute===rowData.id)?<EmptyRouteSlider routes={emptyRoute}/>:<EmptyRouteSlider auth={auth}routes={rowData.emptyRoute}/>}
              </div>
            ) : (
              "Not Added"
            ),
            // </button>
          editComponent: (props) => (
            <button style={{maxHeight:"200px",overflow:'auto',padding: '8px 12px'}} onClick={() => openEmptyRouteModal(props.rowData)}>
              {props.rowData.emptyRoute.length>0||(emptyRoute)
                ?
                <>
                 {(emptyRoute&&rowToUpdate?.emptyRoute===props.rowData.id)?
                   <>
                     {emptyRoute.length>0?
                       <>
                          {emptyRoute?.map((route,index) => (
                              <div style={{borderBottom:"1px solid red",margin:"5px 0 5px 0"}} key={index}> <span style={{fontSize:"13px",fontWeight:"600"}}>{route?.emptyRouteFrom?.add}</span> <span style={{color:"red",fontWeight:"800"}}><ArrowForward style={{fontSize:"13px"}}/></span>  <span style={{fontSize:"13px",fontWeight:"700"}}> {route?.emptyRouteTo?.add}</span></div>
    
                          ))}
                      
                      </>
                     :
                        "Add"
                      }
                   </>
                  :
                  <>
                     {props.rowData.emptyRoute.length>0?
                        <>
                          {props.rowData.emptyRoute?.map((route,index) => (
                              <div style={{borderBottom:"1px solid #00BBE0",margin:"15px 0 10px 0"}} key={index}> <span style={{fontSize:"13px",fontWeight:"600"}}>{route?.emptyRouteFrom?.add}</span> <span style={{color:"red",fontWeight:"800"}}><ArrowForward style={{fontSize:"13px"}}/></span>  <span style={{fontSize:"13px",fontWeight:"700"}}> {route?.emptyRouteTo?.add}</span></div>
                          ))}
    
                        </> 
                        :
                        "Add"
                    }
                  </>
                   }
                </>
                : "Add"}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            width: 200,
            minWidth: 200,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Home City",
          field: "baseCity",
          editable: "onUpdate",
          render: (rowData) =>
          rowData.baseCity||baseCity?.description ? (
              <div>
                <p>{(baseCity?.description&&rowToUpdate?.baseCity===rowData.id)?baseCity?.description:rowData?.baseCity?.add}</p>
              </div>
            ) : (
              "Missing Home City"
            ),
          editComponent: (props) => (
            <button onClick={() => openHomeBaseModal(props.rowData)}>
              {props.rowData.baseCity||baseCity?.description ? (
                <div>
                   <p>{(baseCity?.description&&rowToUpdate?.baseCity===props.rowData.id)?baseCity?.description:props.rowData?.baseCity?.add}</p>
                </div>
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Return Home Date",
          field: "returnHomeDate",
          editable: "onUpdate",
          render: (rowData) =>
            rowData.returnHomeDate||returnHomeDate ? (
              <div>
                 
                <p>
                  {(returnHomeDate&&rowToUpdate?.returnHomeDate===rowData.id)?
                  (new Date(returnHomeDate).getTime()>new Date().getTime()? 
                     moment(returnHomeDate).format("MM/DD/YYYY"):
                     "Missing Return Home Date"
                   ):
                   (new Date(rowData.returnHomeDate).getTime()>new Date().getTime()? 
                     moment(rowData.returnHomeDate).format("MM/DD/YYYY"):
                     "Missing Return Home Date"
                   )
                  }</p>
              </div>
            ) : (
              "Missing Return Home Date"
            ),
            // </button>
          editComponent: (props) => (
            <button onClick={() => openReturnHomeDateModal(props.rowData)}>
              {props.rowData.returnHomeDate||returnHomeDate ? (
                <div>
                  <p>{(returnHomeDate&&rowToUpdate?.returnHomeDate===props.rowData.id)?moment(returnHomeDate).format("MM/DD/YYYY"):moment(props.rowData.returnHomeDate).format("MM/DD/YYYY")}</p>
                </div>
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("trailer_type"),
          field: "trailerType",
          default:"",
          lookup: trailers,
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Truck Make",
          field: "vehicleMake",
          initialEditValue: "",
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: '#E2F6F9',
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
            // backgroundColor: "#74d9e8"
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("vehicle_model_no"),
          field: "vehicleModel",
          initialEditValue: "",
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Truck Number",
          field: "vehicleNumber",
          initialEditValue: "",
          type: Number,
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              type="text"
              placeholder="Truck Number"
              value={props.value}
              onChange={e => {
                const newValue = e.target.value
                props.onChange(newValue)
              }}
            />
          )
        },
        {
          title: "Load In Progress",
          field: "queue",
          type: "boolean",
          render: (rowData) =><div>
              {!rowData.queue&&
                <div>
                  <div>No Load in progress</div>
                  {rowData.emailVerified&&rowData.approved&&
                      <>
                        {(isSuperDispatcher()||auth.info?.profile?.dispatcherId)?
                            <Link href={`/addbookings?user=${rowData.id}&&carrier=${rowData.fleetadmin}`}><Button>Assign Manually</Button></Link>:
                           <Link href={`/addbookings?user=${rowData.id}`}><Button style={{backgroundColor:pageBackgroundColor()}}>Assign Manually</Button></Link>}
                      </>
                   }
                </div>
              }
               {rowData.queue&&<CheckIcon/>}
            </div>,
          initialEditValue: false,
          editable: "never",
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#D6F2F6",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("mobile"),
          field: "mobile",
          editable: "onAdd",
          render: (rowData) =>
            settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("email"),
          field: "email",
          editable: "onAdd",
          render: (rowData) =>
            settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title:'Telegram Username',
          field: "TelegramId",
          editable: "never",
          render: (rowData) =>{
            return(
                <div>{rowData?.TelegramId?.username?<a target='_blank' href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}>{rowData?.TelegramId.username}</a>:"Not Added"}</div>
            ) 
            },
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("lisence_image"),
          editable: "onUpdate",
          field: "licenseImage",
          render: (rowData) =>
            // <button onClick={() => { onClick(rowData, rowData.licenseImage) }}>
            rowData.licenseImage ? (
              <img
                alt="License"
                src={rowData.licenseImage}
                style={{ width: 100 }}
              />
            ) : (
              "Add"
            ),
            // </button>
          editComponent: (props) => (
            <button
              onClick={() => {
                onClick(props.rowData);
              }}
            >
              {props.rowData.licenseImage ? (
                <img
                  alt="License"
                  src={props.rowData.licenseImage}
                  style={{ width: 100 }}
                />
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              // backgroundColor: "#0e7c8c",
              backgroundColor: '#C6E8EE',
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("profile_image"),
          field: "profile_image",
          render: (rowData) =>
            rowData.profile_image ? (
              <img
                alt="Profile"
                src={rowData.profile_image}
                style={{ width: 50, borderRadius: "50%" }}
              />
            ) : (
              "No Profile Picture"
            ),
          editable: "never",
          cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("account_approve"),
          field: "approved",
          type: "boolean",
          initialEditValue: true,
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Owner Operator",
          field: "owner",
          type: "boolean",
          initialEditValue: false,
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        
        { title: "Tracking Method",  
          field: 'trackigType',
          lookup: driverTrackingType, 
          editable: "onUpdate",
          render: (rowData) =>
            rowData.trackigType&& driverTrackingType ? (
               <span>{showSelectedTrackingMethod(rowData.trackigType)}</span>
            ) : (
              "No Set"
            ),
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        { title: "Registered Devices",  
          field: 'registeredDeVicee',
          editable: "never",
          render: (rowData) =>
            rowData.mobileTokens && rowData.mobileTokens.length>0 ? (
               <div>
                  {rowData.mobileTokens.map((e,index)=>{
                      return (
                      <div style={{marginBottom:7}}>
                            <div>{e.deviceName}</div>
                            <div style={{fontSize:13,fontWeight:400}}>Id: {e.deviceId}</div>
                            <div style={{fontSize:11,fontWeight:400,opacity:0.7}}>Joined at {moment(e.joinedDate).format('lll') }</div>
                      </div>
                      )
                  })}
                  <Button onClick={()=>clearMobileTokens(rowData)} style={{fontWeight:'bold',background:pageBackgroundColor()}}>Clear all Devices</Button>
               </div>
            ) : (
              "No Set"
            ),
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        
        { title: "Email Verified",  field: 'emailVerified', type:'boolean',  editable:'never', 
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
      ])
    }
    else if(auth.info?.profile){
      setColumns([
        {
          title: t("first_name"),
          field: "firstName",
          initialEditValue: "",
          fixed: 'left',
          cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'100px',
            zIndex: smallScreen ? 1 : 3
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'100px',
            top: '0',
            zIndex: smallScreen ? 1 : 13
          },
        },
        {
          title: t("last_name"),
          field: "lastName",
          initialEditValue: "",
          cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 3
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: smallScreen ? 'relative' : 'sticky',
            left: smallScreen ? '0px' :'200px',
            zIndex: smallScreen ? 1 : 13
          },
        },
        // { title: "Other Info", field: 'other_info', initialEditValue: '', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
        //  },
        {
          title: "Current Location",
          field: "location",
          editable: "never",
          render: (rowData) =>
            (
              !locationdata?.loading?
                <div>
                  {driverLocation(rowData?.id)?
                      <a  target="__blank" href={getGoogleMapsLink(driverLocation(rowData?.id).lat,driverLocation(rowData?.id).lng)}>
                         {driverLocation(rowData?.id)?.add}
                      </a>
                    :
                    <p>Missing Location</p>
                    }
                </div>
                :
              <CircularProgress size={15}/>
            ),
          editComponent: (props) => (
            <button onClick={() => openLocationModal(props.rowData)}>
              {props.rowData.location||location?.description ? (
                <div>
                   <p>{(location?.description&&rowToUpdate?.location===props.rowData.id)?location?.description:props.rowData?.location?.add}</p>
                </div>
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Open For Duty",
          field: "driverActiveStatus",
          type: "boolean",
          initialEditValue: true,
          render: (rowData) =>
            rowData.driverActiveStatus ? (
                  "yes"
              ) : (
                "No"
              ),
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#D6F2F6",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Maxiumum Weight",
          field: "maximumWeight",
          initialEditValue: "",
          type: Number,
          render: (rowData) =>(
            <div>{rowData.maximumWeight?`${rowData.maximumWeight} Kg`:`0 Kg`}</div>
         ),
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Maximum Weight"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: "Loaded Weight",
          field: "availableWeight",
          initialEditValue: 0,
          type: Number,
          render: (rowData) =>(
            <div>{rowData.availableWeight?`${rowData.availableWeight} Kg`:`0 Kg`}</div>
         ),
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Loaded Weight"
              value={props.value}
              disabled={!props?.rowData.maximumWeight}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                   props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: "Travel Radius (Mile)",
          field: "travelRadius",
          initialEditValue: "",
          type: Number,
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Travel Radius"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else props.onChange(0)
              }}
            />
          )
        },
        {
          title: `Rate Per ${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`,
          field: "ratePerMile",
          initialEditValue: "",
          render: (rowData) =>(
             <div>{rowData.ratePerMile?`$${rowData.ratePerMile}/${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`:"Unknown"}</div>
          ),
          type: Number,
          cellStyle: {
            // backgroundColor: "#39a9ba",
            backgroundColor: '#D6F2F6',
            width: 150,
            minWidth: 150,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              placeholder="Rate Per Mile"
              value={props.value}
              type='number'
              onChange={e => {
                const newValue = e.target.value
                if(Number(newValue)){
                  props.onChange(newValue)
                }
                else  props.onChange(0)
                
              }}
            />
          )
        },
        {
          title: "Preferred Lane",
          field: "frequentRoute",
          editable: "onUpdate",
          render: (rowData) =>
       
            (rowData.frequentRoute||(pickupAddress?.description&&dropAddress?.description)) ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "6px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                  <div>{(pickupAddress?.description&&rowToUpdate?.pickupAddress===rowData.id)?pickupAddress?.description:rowData?.frequentRoute?.cityOne?.add} </div>
                  {((pickupAddress?.description&&rowToUpdate?.pickupAddress===rowData.id)||rowData?.frequentRoute?.cityOne?.add)&&
                  <div style={{ color: "red", width: "fit-content" }}>
                    <MultipleStopIcon />
                  </div>}
                  <div>{(dropAddress?.description&&rowToUpdate?.dropAddress===rowData.id)?dropAddress?.description:rowData?.frequentRoute?.cityTwo?.add}</div>
                </div>
    
                {((frequentRouteratePerMile&&rowToUpdate?.frequentRouteratePerMile===rowData.id)||rowData?.frequentRouteratePerMile)&&
                  <div  style={{marginTop:"5px",fontSize:"11px",fontWeight:"500"}}>
                     {/* <span style={{fontWeight:"500"}}>Rate Per {isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}: </span> */}
                     ${(frequentRouteratePerMile&&rowToUpdate?.frequentRouteratePerMile===rowData.id)?frequentRouteratePerMile:rowData?.frequentRouteratePerMile||0}/{isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"} 
                  </div>}
                
                 {(rowData?.frequentRouteDates?.length>0||frequentRouteDates) ? (
                      <div style={{fontSize:"11px",marginTop:"5px"}}>
                            <div style={{fontSize:"11px",fontWeight:"500"}}>Delivery Dates & Times</div>
                            {(frequentRouteDates&&frequentRouteDates?.length>0&&rowToUpdate?.frequentRouteDates===rowData.id)?
                            frequentRouteDates?.join(", "):
                              (rowData?.frequentRouteDates?.length>0?rowData?.frequentRouteDates?.join(", "): 
                               <span>No Delivery Dates & Times Found</span>
                              )
                            }
                      </div>
                    ) : (
                      <div style={{marginTop:"5px",fontSize:"11px"}}>No Delivery Dates & Times Found</div>
                    )}
              </>
            ) : (
              "Add"
            ),
            // </button>
          editComponent: (props) => (
            <span>
                <button onClick={() => openFrequentRouteModal(props.rowData)}>
                  {props.rowData.frequentRoute||(pickupAddress?.description&&dropAddress?.description) ? (
                    <div>
                      <p>City 1: {(pickupAddress?.description&&rowToUpdate?.pickupAddress===props.rowData.id)?pickupAddress?.description:props.rowData.frequentRoute?.cityOne?.add}</p>
                      <p>City 2: {(dropAddress?.description&&rowToUpdate?.dropAddress===props.rowData.id)?dropAddress?.description:props.rowData.frequentRoute?.cityTwo?.add}</p>
                      <div>Rate Per {isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}: {frequentRouteratePerMile?frequentRouteratePerMile:props.rowData.frequentRouteratePerMile}</div>
                    </div>
                  ) : (
                    "Add"
                  )}
                </button>
                
                <div  style={{marginTop:"20px"}}>
                  <button onClick={() => openFrequentRouteDateModal(props.rowData)}>
                    <div style={{textAlign:"center",marginBottom:"10px",fontWeight:"700"}} >Delivery Dates & Timess</div>
                    {props.rowData.frequentRouteDates||(frequentRouteDates&&frequentRouteDates?.length>0)
                      ?
                      <>
                      {(frequentRouteDates&&frequentRouteDates?.length>0&&rowToUpdate?.frequentRouteDates===props.rowData.id)?
                        <>
                        {frequentRouteDates?.map((day) => (
                          <div
                            key={day.value}
                          >
                            <p
                              style={{
                                color: props?.rowData?.frequentRouteDates?.includes(day)
                                  ? pageBackgroundColor()
                                  : "black",
                              }}
                            >
                              {day}
                            </p>
                          </div>
                        ))}
                        </>
                      :
                      <>
                          {props.rowData.frequentRouteDates&&props.rowData.frequentRouteDates.length>0&&props.rowData.frequentRouteDates.map((day) => (
                            <div
                              key={day.value}
                            >
                              <p
                                style={{
                                  color: props.rowData.frequentRouteDates.includes(day)
                                    ? pageBackgroundColor()
                                    : "black",
                                }}
                              >
                                {day}
                              </p>
                            </div>
                          ))}
                        </>
                        }
                      </>
                      : "Add Delivery Dates & Times"}
                  </button>
    
                </div>
    
            </span>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            width: 400,
            minWidth: 400,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Empty Route Posts",
          field: "emptyRoute",
          editable: "onUpdate",
          render: (rowData) =>
            rowData.emptyRoute.length>0||(emptyRoute) ? (
              <div style={{width: 300}}>
                {(emptyRoute&&rowToUpdate?.emptyRoute===rowData.id)?<EmptyRouteSlider routes={emptyRoute}/>:<EmptyRouteSlider auth={auth}routes={rowData.emptyRoute}/>}
              </div>
            ) : (
              "Not Added"
            ),
            // </button>
          editComponent: (props) => (
            <button style={{maxHeight:"200px",overflow:'auto',padding: '8px 12px'}} onClick={() => openEmptyRouteModal(props.rowData)}>
              {props.rowData.emptyRoute.length>0||(emptyRoute)
                ?
                <>
                 {(emptyRoute&&rowToUpdate?.emptyRoute===props.rowData.id)?
                   <>
                     {emptyRoute.length>0?
                       <>
                          {emptyRoute?.map((route,index) => (
                              <div style={{borderBottom:"1px solid red",margin:"5px 0 5px 0"}} key={index}> <span style={{fontSize:"13px",fontWeight:"600"}}>{route?.emptyRouteFrom?.add}</span> <span style={{color:"red",fontWeight:"800"}}><ArrowForward style={{fontSize:"13px"}}/></span>  <span style={{fontSize:"13px",fontWeight:"700"}}> {route?.emptyRouteTo?.add}</span></div>
    
                          ))}
                      
                      </>
                     :
                        "Add"
                      }
                   </>
                  :
                  <>
                     {props.rowData.emptyRoute.length>0?
                        <>
                          {props.rowData.emptyRoute?.map((route,index) => (
                              <div style={{borderBottom:"1px solid #00BBE0",margin:"15px 0 10px 0"}} key={index}> <span style={{fontSize:"13px",fontWeight:"600"}}>{route?.emptyRouteFrom?.add}</span> <span style={{color:"red",fontWeight:"800"}}><ArrowForward style={{fontSize:"13px"}}/></span>  <span style={{fontSize:"13px",fontWeight:"700"}}> {route?.emptyRouteTo?.add}</span></div>
                          ))}
    
                        </> 
                        :
                        "Add"
                    }
                  </>
                   }
                </>
                : "Add"}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            width: 200,
            minWidth: 200,
            textAlign: isRTL === "rtl" ? "right" : "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Home City",
          field: "baseCity",
          editable: "onUpdate",
          render: (rowData) =>
          rowData.baseCity||baseCity?.description ? (
              <div>
                <p>{(baseCity?.description&&rowToUpdate?.baseCity===rowData.id)?baseCity?.description:rowData?.baseCity?.add}</p>
              </div>
            ) : (
              "Missing Home City"
            ),
          editComponent: (props) => (
            <button onClick={() => openHomeBaseModal(props.rowData)}>
              {props.rowData.baseCity||baseCity?.description ? (
                <div>
                   <p>{(baseCity?.description&&rowToUpdate?.baseCity===props.rowData.id)?baseCity?.description:props.rowData?.baseCity?.add}</p>
                </div>
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Return Home Date",
          field: "returnHomeDate",
          editable: "onUpdate",
          render: (rowData) =>
            rowData.returnHomeDate||returnHomeDate ? (
              <div>
                 
                <p>
                  {(returnHomeDate&&rowToUpdate?.returnHomeDate===rowData.id)?
                  (new Date(returnHomeDate).getTime()>new Date().getTime()? 
                     moment(returnHomeDate).format("MM/DD/YYYY"):
                     "Missing Return Home Date"
                   ):
                   (new Date(rowData.returnHomeDate).getTime()>new Date().getTime()? 
                     moment(rowData.returnHomeDate).format("MM/DD/YYYY"):
                     "Missing Return Home Date"
                   )
                  }</p>
              </div>
            ) : (
              "Missing Return Home Date"
            ),
            // </button>
          editComponent: (props) => (
            <button onClick={() => openReturnHomeDateModal(props.rowData)}>
              {props.rowData.returnHomeDate||returnHomeDate ? (
                <div>
                  <p>{(returnHomeDate&&rowToUpdate?.returnHomeDate===props.rowData.id)?moment(returnHomeDate).format("MM/DD/YYYY"):moment(props.rowData.returnHomeDate).format("MM/DD/YYYY")}</p>
                </div>
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("trailer_type"),
          field: "trailerType",
          default:"",
          lookup: trailers,
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Truck Make",
          field: "vehicleMake",
          initialEditValue: "",
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: '#E2F6F9',
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
            // backgroundColor: "#74d9e8"
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("vehicle_model_no"),
          field: "vehicleModel",
          initialEditValue: "",
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Truck Number",
          field: "vehicleNumber",
          initialEditValue: "",
          type: Number,
          cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            backgroundColor: "#E2F6F9",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          editComponent: props => (
            <TextField
              type="text"
              placeholder="Truck Number"
              value={props.value}
              onChange={e => {
                const newValue = e.target.value
                props.onChange(newValue)
              }}
            />
          )
        },
        {
          title: "Load In Progress",
          field: "queue",
          type: "boolean",
          render: (rowData) =><div>
              {!rowData.queue&&
                <div>
                  <div>No Load in progress</div>
                  {rowData.emailVerified&&rowData.approved&&
                      <>
                        {(isSuperDispatcher()||auth.info?.profile?.dispatcherId)?
                            <Link href={`/addbookings?user=${rowData.id}&&carrier=${rowData.fleetadmin}`}><Button style={{backgroundColor:pageBackgroundColor()}}>Assign Manually</Button></Link>:
                           <Link href={`/addbookings?user=${rowData.id}`}><Button>Assign Manually</Button></Link>}
                      </>
                   }
                </div>
              }
               {rowData.queue&&<CheckIcon/>}
            </div>,
          initialEditValue: false,
          editable: "never",
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#D6F2F6",
            width: 150,
            minWidth: 150,
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("mobile"),
          field: "mobile",
          editable: "onAdd",
          render: (rowData) =>
            settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("email"),
          field: "email",
          editable: "onAdd",
          render: (rowData) =>
            settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title:'Telegram Username',
          field: "TelegramId",
          editable: "never",
          render: (rowData) =>{
            return(
                <div>{rowData?.TelegramId?.username?<a target='_blank' href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}>{rowData?.TelegramId.username}</a>:"Not Added"}</div>
            ) 
            },
            cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("lisence_image"),
          editable: "onUpdate",
          field: "licenseImage",
          render: (rowData) =>
            // <button onClick={() => { onClick(rowData, rowData.licenseImage) }}>
            rowData.licenseImage ? (
              <img
                alt="License"
                src={rowData.licenseImage}
                style={{ width: 100 }}
              />
            ) : (
              "Add"
            ),
            // </button>
          editComponent: (props) => (
            <button
              onClick={() => {
                onClick(props.rowData);
              }}
            >
              {props.rowData.licenseImage ? (
                <img
                  alt="License"
                  src={props.rowData.licenseImage}
                  style={{ width: 100 }}
                />
              ) : (
                "Add"
              )}
            </button>
          ),
          cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              // backgroundColor: "#0e7c8c",
              backgroundColor: '#C6E8EE',
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("profile_image"),
          field: "profile_image",
          render: (rowData) =>
            rowData.profile_image ? (
              <img
                alt="Profile"
                src={rowData.profile_image}
                style={{ width: 50, borderRadius: "50%" }}
              />
            ) : (
              "No Profile Picture"
            ),
          editable: "never",
          cellStyle: {
              fontWeight: "bold",
              textAlign: isRTL === "rtl" ? "right" : "center",
              backgroundColor: "#C6E8EE",
              width: 150,
              minWidth: 150,
              position: 'sticky',
              left: smallScreen ? '0px' :'350px',
              zIndex: 2
            },
          headerStyle: {
            backgroundColor: pageBackgroundColor(),
            fontWeight: "bold",
            color: "white",
            fontSize: 15,
            width: 200,
            minWidth: 200,
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: t("account_approve"),
          field: "approved",
          type: "boolean",
          initialEditValue: true,
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        {
          title: "Owner Operator",
          field: "owner",
          type: "boolean",
          initialEditValue: false,
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        
        { title: "Tracking Method",  
          field: 'trackigType', 
          render: (rowData) =>
            rowData.trackigType&& driverTrackingType ? (
               <span>{showSelectedTrackingMethod(rowData.trackigType)}</span>
            ) : (
              "No Set"
            ),
          initialEditValue: '',
          editable: "never",
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
        { title: "Registered Devices",  
        field: 'registeredDeVicee',
        editable: "never",
        render: (rowData) =>
          rowData.mobileTokens && rowData.mobileTokens.length>0 ? (
             <div>
                {rowData.mobileTokens.map((e,index)=>{
                    return (
                    <div style={{marginBottom:7}}>
                          <div>{e.deviceName}</div>
                          <div style={{fontSize:13,fontWeight:400}}>Id: {e.deviceId}</div>
                          <div style={{fontSize:11,fontWeight:400,opacity:0.7}}>Joined at {moment(e.joinedDate).format('lll') }</div>
                    </div>
                    )
                })}
                <Button onClick={()=>clearMobileTokens(rowData)} style={{fontWeight:'bold',background:pageBackgroundColor()}}>Clear all Devices</Button>
             </div>
          ) : (
            "No Set"
          ),
        cellStyle: {
          fontWeight: "bold",
          textAlign: isRTL === "rtl" ? "right" : "center",
          backgroundColor: "#92b3b8",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          position: 'sticky',
          left: smallScreen ? '0px' :'350px',
          zIndex: 2
        },
      },
        
        { title: "Email Verified",  field: 'emailVerified', type:'boolean',  editable:'never', 
          cellStyle: {
            fontWeight: "bold",
            textAlign: isRTL === "rtl" ? "right" : "center",
            backgroundColor: "#92b3b8",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
          headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            position: 'sticky',
            left: smallScreen ? '0px' :'350px',
            zIndex: 2
          },
        },
      ])
    }
},[auth,generalTabelData,allDriversLocation,availableRoutes,routes])



const clearMobileTokens=(rowData)=>{

  if(rowData.id){
     dispatch(api.clearDevices(rowData.id))
  }
}
  
  // const [returnHomeModal, setReturnHomeModal] = useState(false);
  // const [capacityModal, setCapacityModal] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const handleProfileModal = (e) => {
    setProfileModal(false);
   // setSelectedImage(null);
  };

 
  const handleFrequentRouteModal = (e) => {
    setFrequentRouteModal(false);
    //setPickupAddress(null)
    //setDropAddress(null)
  };

  const handleFrequentRouteDateModal = (e) => {
    setFrequentRouteDateModal(false);
    //setFrequentRouteDates([]);
  };

  const handleEmptyRouteModal = (e) => {
    setEmptyRouteModal(false);
    //setFrequentRouteDates([]);
  };

  const handleHomeBaseModal = (e) => {
    setHomeBaseModal(false);
    //setBaseCity(null)
  };

  const handleLocationModal=()=>{
    setLocationModal(false)
  }

  const handleReturnHomeDateModal = (e) => {
    setReturnHomeDateModal(false);
    //setReturnHomeDate(null);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
    setDriverNotVerifiedText(false)
  };


  const handleSetProfileModal = (e) => {
    e.preventDefault();
    if (selectedImage) {
      setLoading(true);
      dispatch(updateLicenseImage(userData, selectedImage));
      setProfileModal(false);
     // setSelectedImage(null);
      setTimeout(() => {
        setLoading(false);
        dispatch(fetchUsersOnce());
      }, 10000);
    } else {
      setCommonAlert({ open: true, msg: t("choose_image_first") });
    }
  };


  const handleSetFrequentRouteModal = (e) => {
    e.preventDefault();


    if (
      dropAddress &&
      dropAddress.coords &&
      pickupAddress &&
      pickupAddress.coords&&
      frequentRouteratePerMile
    ) {
      setLoading(true);

      const newFrequentRoute = {
        cityOne: {
          add: pickupAddress.description,
          lat: pickupAddress.coords.lat,
          lng: pickupAddress.coords.lng,
        },
        cityTwo: {
          add: dropAddress.description,
          lat: dropAddress.coords.lat,
          lng: dropAddress.coords.lng,
        },
      };

      // Check if it's an add or update action
      if (userData) {
        // Update action
        dispatch(
          editUser(userData.id, {
            ...userData,
            frequentRoute: newFrequentRoute,
            frequentRouteratePerMile:frequentRouteratePerMile
          },userData)
        );
        dispatch(fetchUsersOnce());
      }
    } else {
      setCommonAlert({ open: true, msg: `Select Pickup, Drop Off and Rate per ${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"} First`});
    }

    setLoading(false);
    setFrequentRouteModal(false);
  };

  const handleSetFrequentRouteDateModal = (e) => {
    e.preventDefault();

    if (frequentRouteDates) {
      setLoading(true);

      // Check if it's an add or update action
      if (userData) {
        // Update action
        try{
          dispatch(
            editUser(userData.id, {
              ...userData,
              frequentRouteDates: frequentRouteDates,
            },userData)
          );

        }catch(error){
        }
        dispatch(fetchUsersOnce());
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Pickup and Drop Off First" });
    }

    setLoading(false);
    setFrequentRouteDateModal(false);
  };

  
  const handleSetHomeBaseModal = (e) => {
    e.preventDefault();

    if (baseCity) {
      setLoading(true);

      // Check if it's an add or update action
      const newBaseCity = {
        add: baseCity.description,
        lat: baseCity.coords.lat,
        lng: baseCity.coords.lng,
      };
      if (userData) {
        // Update action
        dispatch(editUser(userData.id, { ...userData, baseCity: newBaseCity },userData));
        dispatch(fetchUsersOnce());
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Driver Home City" });
    }

    setLoading(false);
    setHomeBaseModal(false);
  };

  
  const handleSetLocationModal=(e)=>{
    e.preventDefault();

    if (location) {
      setLoading(true);
      const newLocation = {
        add: location.description,
        lat: location.coords.lat,
        lng: location.coords.lng,
      };

      if (userData) {
        dispatch(editUser(userData.id, { ...userData, location: newLocation },userData));
        setTimeout(()=>{
          dispatch(fetchUsersOnce());
         },1000)
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Driver Location" });
    }

    setLoading(false);
    setLocationModal(false);
  }

  const handleSetReturnHomeDateModal = (e) => {
    e.preventDefault();

    if (returnHomeDate) {
      setLoading(true);

      // Check if it's an add or update action
      if (userData) {
        // Update action
        const formattedDate = Number(moment(returnHomeDate).format("x"));
        dispatch(
          editUser(userData.id, { ...userData, returnHomeDate: formattedDate },userData)
        );
        dispatch(fetchUsersOnce());
      }
    } else {
      setCommonAlert({ open: true, msg: "Select Return Home Date" });
    }

    setLoading(false);
    setReturnHomeDateModal(false);
  };

  function EditRowDone(oldData,newData){ 
    let datatToBeUpdate={...newData}

    if(returnHomeDate&&rowToUpdate?.returnHomeDate===datatToBeUpdate.id){
      const formattedDate = Number(moment(returnHomeDate).format("x"));
      datatToBeUpdate["returnHomeDate"]=formattedDate
    }

    if(baseCity&&rowToUpdate?.baseCity===datatToBeUpdate.id){
        const newBaseCity = {
            add: baseCity.description,
            lat: baseCity.coords.lat,
            lng: baseCity.coords.lng,
          };
        datatToBeUpdate["baseCity"]=newBaseCity
      }
    
    if(location&&rowToUpdate?.location===datatToBeUpdate.id){
      const newLocation = {
          add: location.description,
          lat: location.coords.lat,
          lng: location.coords.lng,
        };
      datatToBeUpdate["location"]=newLocation
    }

    if(frequentRouteDates&&rowToUpdate?.frequentRouteDates===datatToBeUpdate.id){
        datatToBeUpdate["frequentRouteDates"]=frequentRouteDates
      }

    if(frequentRouteratePerMile&&rowToUpdate?.frequentRouteratePerMile===datatToBeUpdate.id){
        datatToBeUpdate["frequentRouteratePerMile"]=frequentRouteratePerMile
      }

    if ((dropAddress &&dropAddress.coords&&rowToUpdate?.dropAddress===datatToBeUpdate.id) && (pickupAddress&&pickupAddress.coords&&rowToUpdate?.pickupAddress===datatToBeUpdate.id)) {

        const newFrequentRoute = {
          cityOne: {
            add: pickupAddress.description,
            lat: pickupAddress.coords.lat,
            lng: pickupAddress.coords.lng,
          },
          cityTwo: {
            add: dropAddress.description,
            lat: dropAddress.coords.lat,
            lng: dropAddress.coords.lng,
          },
        };

        datatToBeUpdate["frequentRoute"]=newFrequentRoute
      }

      if(!datatToBeUpdate?.TelegramId?.username){
        datatToBeUpdate['TelegramId']=''
      }


      
      if(datatToBeUpdate?.trackigType !== "fleetDevice"&&  (datatToBeUpdate?.gpsTracker&&datatToBeUpdate?.gpsTracker!=='default')){
        alert("GPS Tracker Cannot be added when tracking type is different from fleet Gps Asset Tracker ")
        return
      }
      // if(datatToBeUpdate?.trackigType === "fleetDevice"&&  (!datatToBeUpdate?.gpsTracker ||datatToBeUpdate?.gpsTracker==='default')){
      //   alert("GPS Tracker Should be added when tracking type is fleet Gps Asset Tracker ")
      //   return
      // }

      if(datatToBeUpdate?.trackigType !== "fleetDevice"){
        datatToBeUpdate["gpsTracker"] = null
     
     }
      const isGpsExist = generalTabelData?.filter(e=>{
         return e?.gpsTracker === datatToBeUpdate?.gpsTracker && (datatToBeUpdate?.gpsTracker&&datatToBeUpdate?.gpsTracker!=='default')
      })

      if(isGpsExist?.length>0&&isGpsExist[0]?.id !== datatToBeUpdate?.id ){
        alert("GPS Tracker already assigned to a driver, please select another GPS Tracker.")
        return
      }

    

      // if(datatToBeUpdate.gpsTracker && datatToBeUpdate.gpsTracker !== datatToBeUpdate.gpsTracker){
      //   dispatch(editGPSTracker(newData))
      // }

      if(datatToBeUpdate.maximumWeight && datatToBeUpdate.availableWeight && Number(datatToBeUpdate.maximumWeight) && Number(datatToBeUpdate.availableWeight) &&  Number(datatToBeUpdate.maximumWeight) <  Number(datatToBeUpdate.availableWeight)){
           alert("Avaiilable Weight Cannot Exceed Maxiumum Weight") 
           return
      }
      if(datatToBeUpdate.availableWeight && (!datatToBeUpdate.maximumWeight || Number(datatToBeUpdate.maximumWeight) <  Number(datatToBeUpdate.availableWeight))){
          alert("Avaiilable Weight Cannot Exceed Maxiumum Weight") 
          return
      }
      else{
          dispatch(editUser(datatToBeUpdate.id, datatToBeUpdate, oldData));
          setRowToUpdate({})
          setTimeout(()=>{
              dispatch(fetchUsersOnce());
          },1000)

      }

  }

  let [showAddEmptyRoutes,handleShowAddEmptyRoutes]=useState(false)

  const saveChangesEmptyRoute=()=>{
    handleShowAddEmptyRoutes(false)
    setEmptyRouteModal(false);
  }

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser = staticusers.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}


  useEffect(()=>{
    if( (isSuperDispatcher() || auth.info?.profile?.dispatcherId)&&staticusers){
      let SharedCarriers= auth.info?.profile?.dispatcherId ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers:auth.info.profile.SharedCarriers
      if(SharedCarriers&&SharedCarriers.length>0){
        let carriers=[]
        SharedCarriers.map(e=>{
            if(e.approved&&e.aprovedByCarrier){
              const carrier=staticusers.filter(element=>{
                  return element.id===e.id
              })
              if(carrier.length>0){
                    carriers.push({
                        ...carrier[0],
                        'desc': carrier[0].firstName + ' ' + carrier[0].lastName + ' (' + (settings.AllowCriticalEditsAdmin ? carrier[0].mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? carrier[0].email : "Hidden"),

                      })
                  
                }
            }
        })
        SetSharedCarriers(carriers)
       }
       else SetSharedCarriers([])
     }
     else{
      SetSharedCarriers([])
     }

},[auth.info,staticusers,ActiveSharedCarrier])

const [openAddDriverText, handleOpenAddDriverText]=useState({open:false,verification:false})

useEffect(()=>{

  if(!staticusers){ 
    handleOpenAddDriverText({open:false,verification:false})
    }
  else{
          setTimeout(()=>{
              let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
              let isDispatcher = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher'&&!(auth.info?.profile?.independent||auth.info?.profile?.dispatcherId);
      
              if(!isFleetAdmin&&!isDispatcher) handleOpenAddDriverText({open:false,verification:false})
              else  {
                let isThereAnyDriver=staticusers.filter(
                  (user) =>
                    user.usertype === "driver" &&
                    ((user.fleetadmin === auth.info.uid &&
                      auth.info.profile.usertype === "fleetadmin") ||
                     (user.fleetadmin=== auth.info.profile.carrierId&&auth.info.profile.usertype === "dispatcher"))
                )

                let isThereAnyVeriifiedDriver=staticusers.filter(
                  (user) =>
                    user.usertype === "driver" &&
                     user.approved&&user.emailVerified&&
                    ((user.fleetadmin === auth.info.uid &&
                      auth.info.profile.usertype === "fleetadmin") ||
                     (user.fleetadmin=== auth.info.profile.carrierId&&auth.info.profile.usertype === "dispatcher"))
                )

                handleOpenAddDriverText({open:isThereAnyDriver.length===0, verification: isThereAnyVeriifiedDriver.length==0})
              }
          },500)
      }

},[auth,staticusers])

const [addMyselfPage,setAddMySelfPage]=useState(false)
const [myEmail,setMyEmail]=useState("")
const [myPhone,setMyPhone]=useState('')

const AddMySelfAsDriver=()=>{
  handleOpenAddDriverText({open:false,verification:false})
  setAddMySelfPage(true)
}

const [loadingWhileAdding,setLoadingWhileAdding]=useState(false)
const AddDriver=()=>{
  setLoadingWhileAdding(true)
  if(!myEmail||!myPhone) {
    alert("Email and phone are required")
    return
  }
  let newData={
    email:myEmail,
    mobile:myPhone,
    usertype:"driver",
    approved:true,
    owner:true,
    driverActiveStatus:true,
    firstName:auth.info.profile.firstName,
    lastName:auth.info.profile.lastName, 
    pushToken:auth.info.profile?.pushToken,
    queue:false,
    regType:"admin",
  }
  setTimeout(() => {
    checkUserExists(newData).then((res) => {
      if (res.users && res.users.length > 0) {
        alert(t("user_exists"));
      } else if (res.error) {
        alert(t("email_or_mobile_issue"));
      } else {
        
        let role = auth.info.profile.usertype;
       
        if (role === "fleetadmin") {
          newData["fleetadmin"] = auth.info.uid;
        }
        else if(role==='dispatcher') newData["fleetadmin"] = auth.info.profile?.carrierId;
         
        if(newData["fleetadmin"]){
          dispatch(addUser(newData));
        }
        dispatch(fetchUsersOnce());
        setAddMySelfPage(false)
        handleOpenAddDriverText({open:false,verification:false})
      }

      setLoadingWhileAdding(false)
    });
  }, 600);
}
const handleAddMySelfPage=()=>{
  setAddMySelfPage(false)
}


  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      {(isSuperDispatcher() || auth.info?.profile?.dispatcherId)&&<Grid item xs={12} style={{width:"100%", display: ActiveSharedCarrier? '':'flex', justifyContent: ActiveSharedCarrier?'':'space-between', alignItems: ActiveSharedCarrier?'':'center', height: ActiveSharedCarrier?'':'70vh'}}>
                {SharedCariers&&SharedCariers.length>0 ?
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Choose Carrier"}
                    users={SharedCariers}
                    value={ActiveSharedCarrier}
                    onChange={(event, newValue) => {
                      SetActiveSharedCarrier(newValue);
                    }}
                    style={{width:ActiveSharedCarrier?'':'100%'}}
                  />
                  : 
                  <div style={{textAlign:"center",width:"100%"}}>
                      <Typography variant="h4" >No Carrier found </Typography>
                      <div>make a relation <Link href="/my-carriers"> here</Link></div>
                  </div>
                  }
              </Grid> 
            }

      {(isSuperDispatcher() || auth.info?.profile?.dispatcherId) ? 
      ActiveSharedCarrier && <MaterialTable
      title={"Manage Couriers"}
      columns={columns}
      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
      data={generalTabelData}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        actionsCellStyle: {
          position: "sticky",
          left: 0,
          backgroundColor: pageBackgroundColor(),
          zIndex: 3
        },
      }}
      localization={{
        body: {
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit"),
          emptyDataSourceMessage: t("blank_message"),
          editRow: {
            deleteText: t("delete_message"),
            cancelTooltip: t("cancel"),
            saveTooltip: t("save"),
            style: {
              position: "sticky",
              left: 0,
              backgroundColor:"white",
              zIndex: 1,
            }
          },
        },
        toolbar: {
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: "",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
          labelRowsSelect: t("rows"),
          firstTooltip: t("first_page_tooltip"),
          previousTooltip: t("previous_page_tooltip"),
          nextTooltip: t("next_page_tooltip"),
          lastTooltip: t("last_page_tooltip"),
        },
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(t("user_exists"));
                  reject();
                } else if (res.error) {
                  alert(t("email_or_mobile_issue"));
                  reject();
                } else {
                  
                  newData["regType"] = "admin";
                  newData["usertype"] = "driver";
                  newData["licenseImage"] = "";
                  newData["createdAt"] = new Date().toISOString();
                  const c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                  const reference = [...Array(5)]
                    .map((_) => c[~~(Math.random() * c.length)])
                    .join("");
                  let role = auth.info.profile.usertype;
                  if(isSuperDispatcher() || auth.info?.profile?.dispatcherId){
                        newData["fleetadmin"] = ActiveSharedCarrier?.id;
                  }
                  else if (role === "fleetadmin") {
                    newData["fleetadmin"] = auth.info.uid;
                  }
                  else if(role==='dispatcher') newData["fleetadmin"] = auth.info.profile?.carrierId;
                 
                  if(newData["fleetadmin"]){
                    dispatch(addUser(newData));
                    
                  }
                  dispatch(fetchUsersOnce());
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData,oldData) =>
          new Promise((resolve) => {
            // remove tableData via destructuring
          const { tableData, ...cleanData } = newData;
            setTimeout(() => {
              EditRowDone(oldData,cleanData)
              resolve();
            }, 600);
         
          }),
        onRowDelete: (oldData) =>
          settings.AllowCriticalEditsAdmin
            ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteUser(oldData.id));
                  dispatch(fetchUsersOnce());
                }, 600);
              })
            : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }),
      }}
      />
      :<MaterialTable
        title={"Manage Couriers"}
        columns={columns}
        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        data={generalTabelData}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
          actionsCellStyle: {
            position: "sticky",
            left: 0,
            backgroundColor:"white",
            zIndex: 3
          },
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
              style: {
                position: "sticky",
                left: 0,
                backgroundColor: pageBackgroundColor(),
                zIndex: 1
              }
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: "",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            labelRowsSelect: t("rows"),
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t("user_exists"));
                    reject();
                  } else if (res.error) {
                    alert(t("email_or_mobile_issue"));
                    reject();
                  } else {
                    
                    newData["regType"] = "admin";
                    newData["usertype"] = "driver";
                    newData["licenseImage"] = "";
                    newData["createdAt"] = new Date().toISOString();
                    const c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                    const reference = [...Array(5)]
                      .map((_) => c[~~(Math.random() * c.length)])
                      .join("");
                    let role = auth.info.profile.usertype;
                    if(isSuperDispatcher() || auth.info?.profile?.dispatcherId){
                          newData["fleetadmin"] = ActiveSharedCarrier?.id;
                    }
                    else if (role === "fleetadmin") {
                      newData["fleetadmin"] = auth.info.uid;
                    }
                    else if(role==='dispatcher') newData["fleetadmin"] = auth.info.profile?.carrierId;
                     
                    if(auth.info?.profile?.trackigType){
                      newData['trackigType']=auth.info?.profile?.trackigType
                    }
                       
                    if(newData["fleetadmin"]){
                      dispatch(addUser(newData));
                    }
                    dispatch(fetchUsersOnce());
                    resolve();
                  }
                });
              }, 600);
            }),
          onRowUpdate: (newData,oldData) =>
            new Promise((resolve) => {
              // remove tableData via destructuring
            const { tableData, ...cleanData } = newData;
              setTimeout(() => {
                EditRowDone(oldData,cleanData)
                resolve();
              }, 600);
           
            }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                    dispatch(fetchUsersOnce());
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(t("demo_mode"));
                  }, 600);
                }),
        }}
      />}
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
              {t("lisence_image")}
              <input
                type="file"
                style={{ marginLeft: 10 }}
                name={t("image")}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage && !loading ? (
              <Tooltip title={t("cancel")}>
                <CancelIcon
                  onClick={() => setSelectedImage(null)}
                  style={{
                    fontSize: 30,
                    backgroundColor: "red",
                    borderRadius: 50,
                    color: "white",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage ? (
              <img
                alt="not fount"
                height={"200px"}
                src={URL.createObjectURL(selectedImage)}
                style={{ marginTop: 15, marginBottom: 20, width: '100%', objectFit: 'contain' }} 
                />
            ) : (
              <img
                alt="licimage"
                height={"200px"}
                src={imageData}
                style={{ marginTop: 10, width: '100%', objectFit: 'contain' }}
              />
            )}
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleProfileModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetProfileModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={frequentRouteModal}
        onClose={handleFrequentRouteModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"City 1"}
              value={pickupAddress}
              className={classes.items}
              onChange={(value) => {
                setPickupAddress(value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              value={dropAddress}
              className={classes.items}
              placeholder={"City 2"}
              onChange={(value) => {
                setDropAddress(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
             <Input
                variant="outlined"
                value={frequentRouteratePerMile}
                className={classes.items}
                type="number"
                placeholder={`Rate Per ${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (isNaN(inputValue) || inputValue < 0) {
                    // Set the value to 0 or handle the error in another way
                    setFrequentRouteratePerMile(0);
                  } else {
                    setFrequentRouteratePerMile(inputValue);
                  }
                }}
              />
          </Grid>


          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleFrequentRouteModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetFrequentRouteModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={frequentRouteDateModal}
        onClose={handleFrequentRouteDateModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12}>
            {daysOfWeek.map((day) => (
              <div
                key={day.value}
                onClick={() => toggleDaySelection(day.value)}
                style={{
                  backgroundColor: frequentRouteDates?.includes(day.value)
                    ? pageBackgroundColor()
                    : "white",
                  borderColor: frequentRouteDates?.includes(day.value)
                    ? "black"
                    : pageBackgroundColor(),
                }}
              >
                <p
                  style={{
                    color: frequentRouteDates?.includes(day.value)
                      ? "white"
                      : "black",
                  }}
                >
                  {day.label}
                </p>
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleFrequentRouteDateModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetFrequentRouteDateModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={emptyRouteModal}
        onClose={handleEmptyRouteModal}
        className={classes.modal}
        container={() => rootRef.current}
        style={{width:"100%"}}
      > 
          <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ flexDirection: "column"}}
          >     
                <div style={{fontSize:"11px",opacity:0.8,textAlign:"center"}}>
                   {emptyRoute?.length>0&&<span>{emptyRoute?.length} Empty Routes</span>} 
                </div>
               {emptyRoute&&
                 <div style={{height:"80vh",overflow:"auto",width:"100%"}}>
                    <div>
                            <Button onClick={()=>handleShowAddEmptyRoutes(!showAddEmptyRoutes)}><AddIcon/></Button>
                    </div>
                    {showAddEmptyRoutes&&<AddNewEmptyRoute auth={auth} ActiveSharedCarrier={ActiveSharedCarrier} setEmptyRouteModal={setEmptyRouteModal} driverInfoToEmptyRoute={driverInfoToEmptyRoute} trailerTypeToEmptyRoute={trailerTypeToEmptyRoute} setemptyRoute={setemptyRoute} handleShowAddEmptyRoutes={handleShowAddEmptyRoutes}/>}
                    
                    {emptyRoute.map((route,index)=>{
                      return <EachRoutes auth={auth} ActiveSharedCarrier={ActiveSharedCarrier} setEmptyRouteModal={setEmptyRouteModal} emptyRoute={emptyRoute} setemptyRoute={setemptyRoute} route={route} index={index} key={index}/>
                    })}

                   {(emptyRoute?.length==0&&!showAddEmptyRoutes)&&<div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
                     Empty
                     </div>}
                    
                </div>
                }

                <div style={{marginTop:"3px",display:"flex",justifyContent:"center",gap:"10px",alignItems:"center"}}>
                    <div><Button onClick={saveChangesEmptyRoute}>Save</Button></div>
                </div>   

          </Grid>
      </Modal>

      
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={homeBaseModal}
        onClose={handleHomeBaseModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"City 1"}
              value={baseCity}
              className={classes.items}
              onChange={(value) => {
                setBaseCity(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleHomeBaseModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetHomeBaseModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={locationModal}
        onClose={handleLocationModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Location"}
              value={location}
              className={classes.items}
              onChange={(value) => {
                setLocation(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleLocationModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetLocationModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={returnHomeDateModal}
        onClose={handleReturnHomeDateModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            borderRadius: 8,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
          }}
        >
          <Grid item xs={12}>
            <Calendar onChange={handleDateSelect} value={returnHomeDate} />
            {/* moment(selectedDate).format('dddd, MMM D') */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleReturnHomeDateModal}
                  variant="contained"
                  color="dangerColor"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetReturnHomeDateModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>


      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openAddDriverText.open||openAddDriverText.verification}
        onClose={()=>handleOpenAddDriverText({open:false,verification:false})}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            borderRadius: 8,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
          }}
        >
              {
                !openAddDriverText.verification ?
                   <>
                      <p>To access the disabled links, please ensure that you have at least one driver associated with your account.</p>
                      <p>Make sure to approve the driver and remind them to log in at least once to verify their email.</p>
                      <p>Kindly add yourself as a driver or create a new driver profile.</p>
                   </>:
                   <>
                       Please make sure that there is at least one driver who has both an approved account and a verified email address.
                   </>
                  
              }
              <div style={{textAlign:"center"}}><Button onClick={AddMySelfAsDriver}>Add My self</Button></div>
        </Grid>
      </Modal>
      
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={addMyselfPage}
        onClose={handleAddMySelfPage}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            borderRadius: 8,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
          }}
        >
           <p>Kindly consider using an alternate email address and phone number, different from the ones you have previously provided.</p>
           <div style={{width:"100%",marginBottom:"20px"}}>
                  <TextField 
                     style={{width:"100%"}}
                      value={myEmail} 
                      placeholder='Email'
                      onChange={(e)=>setMyEmail(e.target.value)}
                      />
            </div>

            <div  style={{width:"100%",marginBottom:"20px"}}>
                  <TextField 
                       style={{width:"100%"}}
                      value={myPhone} 
                      placeholder='Phone'
                      onChange={(e)=>setMyPhone(e.target.value)}
                      />
             </div>
              {loadingWhileAdding?
                 <div style={{textAlign:"center",marginTop:"15px"}}><CircularProgress size={25}/></div>
               :
                <div style={{textAlign:"center"}}><Button disabled={!myEmail||!myPhone} onClick={AddDriver}>Add</Button></div>
               }
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={routeModal}
        onClose={handleRouteModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Box spacing={1} className={classes.paper}>
          {availableRoutes && availableRoutes.length > 0 ? (
            <>
              <Box item xs={12} style={{ width: "100%" }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Available Routes
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={onlyActiveRoutes(workingRoute)}
                    onChange={handleChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {availableRoutes.map((route) => (
                      <MenuItem
                        key={route.identification}
                        value={route.identification}
                        style={getStyles(
                            route.identification,
                            onlyActiveRoutes(workingRoute),
                            theme
                           )}
                      >
                         <Box sx={{display:"flex",alignItems:"center",gap:0.3,flexWrap:"wrap"}}>
                             <Box sx={{fontSize:14}}> {route?.pickupAddress||route?.pickup?.description}</Box>
                              <Box><EastIcon style={{fontSize:15,marginTop:8}}/></Box>
                             <Box sx={{fontSize:14}}>{route?.dropAddress||route?.drop?.description}</Box>
                            <Box style={{fontWeight:"700"}}>({route?.identification})</Box>
                         </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                {loading ? (
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "5vh" }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
                  >
                    <MuButton
                      onClick={handleRouteModal}
                      variant="contained"
                      color="dangerColor"
                     
                    >
                      {t("cancel")}
                    </MuButton>
                    <Button
                      onClick={handleSetRouteModal}
                      variant="contained"
                      color="secondaryButton"
                      style={{
                        marginLeft: 10,
                        color: "white",
                        background: pageBackgroundColor(),
                      }}
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Typography style={{ fontWeight: 700, fontSize: 20 }}>
              No Available routes found
            </Typography>
          )}
        </Box>
      </Modal>

      <div style={{float: 'right', margin: 10, border: '1px solid #01aac1', padding: 10}}><span style={{padding: '3px 10px', margin: 3, backgroundColor: '#ABE6EF'}}></span>Public capacity board data</div>


      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>

      <AlertDialog open={driverNotVerifiedText} onClose={handleCommonAlertClose}>
          You can't add empty routes for un verified drivers. they must log in at least once
      </AlertDialog>
    </div>
  );
}


const EmptyRouteSlider=({routes,auth})=>{
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const scrollbarStyles = {
    width: '300px',
    height: '150px',
    overflowX: 'hidden',
    padding: '3px 5px 0 5px',
    textAlign: 'center',
    overflowY: 'auto',
     scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::webkitScrollbar': {
      display: 'none',
      width:'0px'
    },
    
  };

  const isUsingMile=(countryCode)=>{
    if(countryCode === 'US') return true
    return false
 }
  return(
    <Slider {...settings} style={scrollbarStyles} >
        {routes.map((slide,index)=>{
            return (
              <div key={index} style={{height:"400px",background:"red",width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",marginTop:"10px"}}>
                <div><span style={{fontSize:"13px",fontWeight:"600"}}>{slide?.emptyRouteFrom?.add}</span> <span style={{color:"red",fontWeight:"800"}}><ArrowForward style={{fontSize:"13px"}}/></span>  <span style={{fontSize:"13px",fontWeight:"700"}}> {slide?.emptyRouteTo?.add}</span></div>
                <div style={{fontSize:"11px",fontWeight:"500",marginTop:"5px"}} >Route Type: {slide.routeType.name||slide.routeType||"Not listed"}</div>
                <div style={{fontSize:"11px",fontWeight:"500"}}>Route Date: {moment(slide.routedate).format('LL')}</div>
                <div style={{fontSize:"11px",fontWeight:"500"}}>${slide.routeRatePerMile}/{isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}</div>
                <div style={{marginTop:"5px",fontWeight:"800",color:"#15A57C"}}>{index+1} of {routes.length}</div>
              </div>
              )
        })}
    </Slider>
  )
}

const AddNewEmptyRoute=({ActiveSharedCarrier,handleShowAddEmptyRoutes,setemptyRoute,driverInfoToEmptyRoute,trailerTypeToEmptyRoute,setEmptyRouteModal,auth})=>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const { api } = useContext(FirebaseContext);
  const {
    fetchRouteTypes,
    addEmptyRoutePost,
    getEstimate,
    clearTripPoints,
    MinutesPassed,
  } = api;
  const { t, i18n } = useTranslation();
  const settings = useSelector((state) => state.settingsdata.settings);
  const estimatedata = useSelector((state) => state.estimatedata);
  const routeTypes = useSelector((state) => state.routetypes.routes);
    
  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);
  
  const [from,setFrom]=useState(null)
  const [to,setTo]=useState(null)
  const [routeDate,setRouteDate]=useState(null)
  const [routeRatePerMile,setRouteRatePerMile]=useState(null)
  const [routeType,setRouteType]=useState(null)
  const [error, setError] = useState("");

  let [showDone,handleShowDone]=useState(false)
  let [postingProgress,handlePostingProgress]=useState(false)
  let [finishProgress,handleFinishProgress]=useState(false)

  useEffect(()=>{
      if(from&&to&&routeDate&&routeType&&routeRatePerMile){
        handleShowDone(true)
      }else{handleShowDone(false)}
  },[from,to,routeDate,routeType,routeRatePerMile])

  const [estimatedValue,handleEstimatedValue]=useState(null)
  useEffect(()=>{
        if(estimatedata?.estimate?.estimateDistance){
           handleEstimatedValue(estimatedata)
        }
  },[estimatedata])
  
  const confirmRoutePost=()=>{
    handlePostingProgress(true)
    if(!trailerTypeToEmptyRoute){
      setError( "Please fill your Trailer type on the table, then try again");
      handlePostingProgress(false)
      setTimeout(()=>{
        setError("")
      },4000)
    }
    else{
        if(from&&to&&routeDate&&routeType&&routeRatePerMile){
            const diffMins = MinutesPassed(routeDate);
    
            if (diffMins < 720) {
              setError( t("route_posting_warning"));
              handlePostingProgress(false)
              setTimeout(()=>{
                setError("")
              },4000)
              } 
    
            else if(estimatedValue){
              const estimateDistance = settings.convert_to_mile ? (Number(estimatedValue.estimate.estimateDistance) / 1.609344) : Number(estimatedValue.estimate.estimateDistance);
              let RouteType=routeTypes.filter(e=>{
                return e.name===routeType
              })
              const routeObject = {
                emptyRouteFrom: { coords: from.coords, description: from.description },
                emptyRouteTo: { coords: to.coords, description: to.description, waypointsStr: null, waypoints: null },
                routeType: RouteType[0],
                driverDetail: driverInfoToEmptyRoute,
                estimateDistance: estimateDistance,
                estimateTime: estimatedValue.estimate.estimateTime,
                routedate: new Date(routeDate).getTime(),
                trailerType:trailerTypeToEmptyRoute,
                userDetails:auth.info,
                ActiveSharedCarrier:ActiveSharedCarrier?.id,
                settings: settings,
                empty_route_post_type_admin: true,
                routeRatePerMile:routeRatePerMile
              };
    
              let data={
                emptyRouteFrom:{add: from.description,lat:from.coords.lat,lng:from.coords.lng},
                emptyRouteTo:{add: to.description,lat:to.coords.lat,lng:to.coords.lng},
                routedate:routeDate,
                routeType:RouteType[0],
                routeRatePerMile:routeRatePerMile
              }
              setemptyRoute(e=>[data,...e])
              dispatch(addEmptyRoutePost(routeObject));
              setFrom(null)
              setTo(null)
              setRouteType(null)
              handleShowAddEmptyRoutes(false)
              setEmptyRouteModal(false)
              handleFinishProgress(false)
              handlePostingProgress(false)
              dispatch(clearTripPoints());
            }
        
        }
        else{
          setError( "Please fill all neccessery fields");
          handlePostingProgress(false)
          setTimeout(()=>{
            setError("")
          },4000)
        }
    }
    //setemptyRoute()
  }
   
  const isUsingMile=(countryCode)=>{
    if(countryCode === 'US') return true
    return false
 }
  
  const initiateRoutePosting = () => {
    if(from&&to&&routeDate&&routeType&&routeRatePerMile){
      handlePostingProgress(true)
      const directionService = new window.google.maps.DirectionsService();
            directionService.route({
                origin: new window.google.maps.LatLng(
                  from.coords.lat,
                  from.coords.lng
                ),
                destination: new window.google.maps.LatLng(
                  to.coords.lat,
                  to.coords.lng
                ),
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  const route = {
                    distance_in_km:
                      result.routes[0].legs[0].distance.value / 1000,
                    time_in_secs: result.routes[0].legs[0].duration.value,
                    polylinePoints: result.routes[0].overview_polyline,
                  };
                 
                  let estimateRequest = {
                    pickup: from,
                    drop: to,
                    routeDetails: route,
                    routeRatePerMile:routeRatePerMile,
                    post_request: null,
                  };
                   dispatch(getEstimate(estimateRequest));
                   setTimeout(()=>{
                   handleFinishProgress(true)
                   handlePostingProgress(false)
                   },500)
                } else {
                  setError(t("place_to_coords_error"))
                  handlePostingProgress(false)
                  setTimeout(()=>{
                    setError("")
                  },10000)
                  
                }
              }
            );
    
      }else{
        setError("Please fill all neccessery fields" );
        handlePostingProgress(false)
        setTimeout(()=>{
          setError("")
        },4000)
    }
    
  };
  
  const ChangeFromAddress=(value)=>{
    if(!finishProgress){
        setFrom(value);
    }
  }

  const ChangeToAddress=(value)=>{
    if(!finishProgress){
       setTo(value);
    }
  }

  const handleDateSelect = (date) => {
    if(new Date(date).getTime()>new Date().getTime()){
       setRouteDate(date); 
     }
  };


  return(
    <div style={{border:"2px solid black",padding:"5px",margin:"5px 0 5px 0"}}> 
        <div  style={{margin:"10px 0 0 0"}}>
           <GoogleMapsAutoComplete
                  variant={"outlined"}
                  placeholder={"From"}
                  value={from}
                  className={classes.items}
                  onChange={ChangeFromAddress}
                  disabled={finishProgress}
             />
          </div>

        <div  style={{margin:"10px 0 0 0"}}>
          <GoogleMapsAutoComplete
                    variant={"outlined"}
                    placeholder={"To"}
                    value={to}
                    className={classes.items}
                    onChange={ChangeToAddress}
                    disabled={finishProgress}

                  />
        </div>

        <div  style={{margin:"10px 0 0 0"}}>
           <Calendar onChange={handleDateSelect} value={routeDate} />
        </div>

        <div  style={{margin:"10px 0 0 0"}}>
           <Input
                variant="outlined"
                value={routeRatePerMile}
                type="number"
                placeholder={`Route Rate Per ${isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}`}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (isNaN(inputValue) || inputValue < 0) {
                    setRouteRatePerMile(0);
                  } else {
                    setRouteRatePerMile(inputValue);
                  }
                }}
              />
        </div>
        
        <div  style={{margin:"10px 0 0 0",width:"200px"}}>
             {routeTypes&&routeTypes.length>0?<FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Route type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={routeType}
                  label="Route type"
                  onChange={(e)=>setRouteType(e.target.value)}
                >
                    {routeTypes.map((e,index)=>{
                       return <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>
                    })}
                </Select>
              </FormControl>:
                <div style={{color:"red"}}>No Route type to show up</div>
              }
        </div>
        
        {error&&<div style={{color:"red",fontSize:"13px"}}>{error}</div>}

        <div style={{marginTop:"3px",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                {postingProgress?
                   <div><CircularProgress size={25}/></div>
                :<>
                  {finishProgress?
                      <div><Button  onClick={confirmRoutePost}>Finish</Button></div>
                     :
                     <>
                        <div><Button disabled={!showDone} onClick={initiateRoutePosting}>Next</Button></div>
                        <div><Button onClick={()=>handleShowAddEmptyRoutes(false)}>Cancel</Button></div>
                      </>  
                      }
                </>}
         </div>

    </div>
)
}

const EachRoutes=({auth,ActiveSharedCarrier,route,setemptyRoute,index,emptyRoute,setEmptyRouteModal})=>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const { api } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const settings = useSelector((state) => state.settingsdata.settings);
  const estimatedata = useSelector((state) => state.estimatedata);

  const {
    updateEmptyRoutePost,
    deleteEmptyRoutePost,
    getEstimate,
    MinutesPassed,
    fetchRouteTypes
  } = api;
  const routeTypes = useSelector((state) => state.routetypes.routes);
    
  // fetch profile
  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);

  const [from,setFrom]=useState({coords: { lat: route?.emptyRouteFrom.lat, lng: route?.emptyRouteFrom.lng },description: route?.emptyRouteFrom?.add|| route?.emptyRouteFrom?.description})
  const [to,setTo]=useState({coords: { lat: route?.emptyRouteTo.lat, lng: route?.emptyRouteTo.lng },description: route.emptyRouteTo?.add||route?.emptyRouteTo?.description})
  const [routeDate,setRouteDate]=useState(route?.routedate)
  const [routeRatePerMile,setRouteRatePerMile]=useState(route?.routeRatePerMile)
  const [routeType,setRouteType]=useState(route?.routeType)
  const [edit,setEdit]=useState(false)

  const [showSaveButton,setShowSaveButton]=useState(false)
  const [postingProgress,handlePostingProgress]=useState(false)
  const [finishProgress,handleFinishProgress]=useState(false)
  const [error, setError] = useState("");

  useEffect(()=>{
    if(from&&to&&routeDate&&routeType&&routeRatePerMile){
        setShowSaveButton(true)
    }else{setShowSaveButton(false)}
  },[from,to,routeDate,routeType,routeRatePerMile])
  const triggerEdit=()=>{
    setEdit(true)
  }
  const SaveEdit=()=>{
    handlePostingProgress(true)     
     if(estimatedata){
    
          const estimateDistance = settings.convert_to_mile ? (Number(estimatedata.estimate.estimateDistance) / 1.609344) : Number(estimatedata.estimate.estimateDistance);
          const routeObject = {
            id:route.id,
            emptyRouteFrom: { coords: from.coords, description: from.description },
            emptyRouteTo: { coords: to.coords, description: to.description, waypointsStr: null, waypoints: null },
            routeType: routeType,
            estimateDistance: estimateDistance,
            estimateTime: estimatedata.estimate.estimateTime,
            routedate: new Date(routeDate).getTime(),
            routeRatePerMile: routeRatePerMile,
            settings: settings,
            empty_route_post_type_admin: true,
            postedAdmin:ActiveSharedCarrier?.id||auth.info.uid,
            usertype:"admin",
            driver:route.driver
          };

          const fromCity = {
            add: from.description,
            lat: from.coords.lat,
            lng: from.coords.lng,
          };
          const toCity = {
            add: to.description,
            lat: to.coords.lat,
            lng: to.coords.lng,
          };

          const formattedDate = Number(moment(routeDate).format("x"));
          setemptyRoute((ele=>ele.map(e=>{
            return e.id===route.id?
                  {...e,
                    emptyRouteFrom:fromCity,
                    emptyRouteTo:toCity,
                    routedate:formattedDate,
                    routeType:routeType,
                    routeRatePerMile:routeRatePerMile
                  }:
                  {...e}
          })))
          

          dispatch(updateEmptyRoutePost(routeObject));

          handleFinishProgress(false)
          handlePostingProgress(false)
          setEdit(false)
        
     }
     else{
      setError("Errorr occurd while estimating please try again");
      handlePostingProgress(false)
      handleFinishProgress(false)
      setTimeout(()=>{
          setError("")
       },4000)
     }

  }

  const initiateRoutePosting = () => {
    if(from&&to&&routeDate&&routeType&&routeRatePerMile){
      handlePostingProgress(true)
      
      const directionService = new window.google.maps.DirectionsService();
            directionService.route({
                origin: new window.google.maps.LatLng(
                  from.coords.lat,
                  from.coords.lng
                ),
                destination: new window.google.maps.LatLng(
                  to.coords.lat,
                  to.coords.lng
                ),
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                const diffMins = MinutesPassed(routeDate);

                if (diffMins < 720) {
                  setError(t("route_posting_warning"));
                  handlePostingProgress(false)
                  setTimeout(()=>{
                    setError("")
                  },4000)
               }
               else if (status === window.google.maps.DirectionsStatus.OK) {
                  const route = {
                    distance_in_km:
                      result.routes[0].legs[0].distance.value / 1000,
                    time_in_secs: result.routes[0].legs[0].duration.value,
                    polylinePoints: result.routes[0].overview_polyline,
                  };
                 
                  let estimateRequest = {
                    pickup: from,
                    drop: to,
                    routeDetails: route,
                    routeRatePerMile:routeRatePerMile,
                    post_request: null,
                  };
                   dispatch(getEstimate(estimateRequest));
                   handleFinishProgress(true)
                   handlePostingProgress(false)
                } 
               else {
                  setError(t("place_to_coords_error"))
                  handlePostingProgress(false)
                  setTimeout(()=>{
                    setError("")
                  },10000)
                  
                }
              }
            );
    
      }else{
        setError("Please fill all neccessery fields" );
        handlePostingProgress(false)
        setTimeout(()=>{
          setError("")
        },4000)
    }
    
  };
  

  const ChangeFromAddress=(value)=>{
    if(!finishProgress){
      setFrom(value);

    }
  }

  const ChangeToAddress=(value)=>{
    if(!finishProgress){
        setTo(value);
    }
  }

  const handleDateSelect = (date) => {
    setRouteDate(date); 
  };

  let [trigerDelete,setTrigerDelete]=useState(false)
  
  function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
  }

  const DeleteRoute=()=>{
    handlePostingProgress(true)
    const data={
      postedAdmin:auth.info.uid,
      postId:route.id,
      usertype:"admin",
      routeDetail:route
    }
    if(isSuperDispatcher() || auth.info?.profile?.dispatcherId) data['postedAdmin']=ActiveSharedCarrier?.id
    let filteredData=emptyRoute.filter((e=>{
      return e.id!==route.id
    }))
    dispatch(deleteEmptyRoutePost(data));
    if(filteredData.length===1){
      //window.location.href="/drivers"
    }
   setemptyRoute(filteredData)
   setEmptyRouteModal(false)
   setTrigerDelete(false)
  }

  const isUsingMile=(countryCode)=>{
    if(countryCode === 'US') return true
    return false
 }
  return(
    <div style={{border:"1px solid red",padding:"5px",margin:"5px 0 5px 0"}}> 
        <div style={{margin:"10px 0 0 0"}}>
            {edit?
                <GoogleMapsAutoComplete
                  variant={"outlined"}
                  placeholder={"From"}
                  value={from}
                  className={classes.items}
                  onChange={ChangeFromAddress}
                  disabled={finishProgress}
                />
            :<div>From: {from?.add||from?.description}</div>}

        </div>

        <div style={{margin:"10px 0 0 0"}}>
            {edit?
                <GoogleMapsAutoComplete
                  variant={"outlined"}
                  placeholder={"To"}
                  value={to}
                  className={classes.items}
                  onChange={ChangeToAddress}
                  disabled={finishProgress}
                />
            :<div>To: {to?.add||to?.description}</div>}

        </div>

        <div style={{margin:"10px 0 0 0"}}>
           {edit?
             <Calendar onChange={handleDateSelect} value={routeDate} />
            : 
           <div>routeDate: {moment(routeDate).format("LL")}</div>
           }
        </div>

        <div style={{margin:"10px 0 0 0"}}>
           {edit?
             <Input
                variant="outlined"
                value={routeRatePerMile}
                type="number"
                placeholder="Route Rate Per Mile"
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (isNaN(inputValue) || inputValue < 0) {
                    setRouteRatePerMile(0);
                  } else {
                    setRouteRatePerMile(inputValue);
                  }
                }}
               />
            : 
           <div>${routeRatePerMile}/{isUsingMile(auth?.info?.profile?.selectedcountry?.code)?"Mile":"KM"}</div>
           }
        </div>

         
        <div style={{margin:"10px 0 0 0",width:"200px"}}>
           {edit?
               <>
                 {routeTypes&&routeTypes.length>0?<FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Route type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={routeType}
                      label="Route type"
                      onChange={(e)=>setRouteType(e.target.value)}
                    >
                        {routeTypes.map((e,index)=>{
                          return <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>
                        })}
                    </Select>
                  </FormControl>:
                    <div style={{color:"red"}}>No Route type to show up</div>
                  }
              </>
               :
              <div> routeType: {routeType?.name||routeType}</div>
            }

       </div>

       {error&&<div style={{color:"red",fontSize:"13px"}}>{error}</div>}
       
      <div style={{marginTop:"3px",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
          {edit?
              <>
                {postingProgress?
                   <div><CircularProgress size={25}/></div>
                :<>
                   {finishProgress?
                      <div><Button  onClick={SaveEdit}>Save</Button></div>
                     : 
                       <>
                          <div><Button disabled={!showSaveButton||!(routeTypes&&routeTypes.length>0)} onClick={initiateRoutePosting}>Next</Button></div>
                          <div><Button onClick={()=>setEdit(false)}>Cancel</Button></div>
                       </>
                   }
                </>}
              </>
           :<>
             {trigerDelete?
                <span style={{color:"red"}}>
                  are you sure on deleting route <Button onClick={DeleteRoute} style={{width:"20px",height:"30px",fontSize:"12px"}}>yes</Button> <Button onClick={()=>setTrigerDelete(false)} style={{width:"20px",height:"30px",fontSize:"12px"}}>No</Button>
                </span>
                :
                <>
                  <div><IconButton onClick={triggerEdit}><EditIcon/></IconButton></div>
                  <div><IconButton onClick={()=>setTrigerDelete(true)}><DeleteIcon style={{color:"red"}}/></IconButton></div>
                </>
             
             }
          </>}
      </div>

       <div style={{textAlign:"center"}}>{index+1}</div>
    </div>

  )
}

const DevicesSlider=({routes})=>{
  
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const scrollbarStyles = {
    width: '300px',
    height: '150px',
    overflowX: 'hidden',
    padding: '3px 5px 0 5px',
    textAlign: 'center',
    overflowY: 'auto',
     scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '::webkitScrollbar': {
      display: 'none',
      width:'0px'
    },
    
  };
  return(
    <Slider {...settings} style={scrollbarStyles} >
        {routes.map((slide,index)=>{
            return (
              <div key={index} style={{height:"400px",background:"red",width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",marginTop:"10px"}}>
                 <div style={{fontSize:16,fontWeight:"bold"}}>{slide.deviceName}</div>
                <div style={{fontSize:"11px",fontWeight:"500"}}>Route Date: {moment(slide.createdDate).format('LL')}</div>
                <div style={{marginTop:"5px",fontWeight:"800",color:"#15A57C"}}>{index+1} of {routes.length}</div>
              </div>
              )
        })}
    </Slider>)
}